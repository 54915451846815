import React, { Component } from 'react';

class LargeBannerAd extends Component {

    render() {
        return (
            <script data-cfasync="false" type="text/javascript" src="//predictiondisplay.com/a/display.php?r=5950818"></script>        );
    }
}

export default LargeBannerAd;


