import { BATCH_RESULTS_FAILURE, BATCH_RESULTS_SUCCESS,BATCH_RESULTS_LOAD ,CONSOLIDATE_RESULTS_LOAD,CONSOLIDATE_RESULTS_SUCCESS,CONSOLIDATE_RESULTS_FAILURE, CODE_RUNNER_START, CODE_RUNNER_SUCCESS, CODE_RUNNER_FAILURE, LOGIN_USER,REGISTER_USER,LOGIN_USER_SUCCESS,LOGIN_USER_FAILURE,REGISTER_USER_SUCCESS,REGISTER_USER_FAILURE, UPLOAD_PROGRAMS, UPLOAD_PROGRAM_SUCCESS, UPLOAD_PROGRAM_FAILURE, LOAD_POTD, LOAD_POTD_SUCCESS, LOAD_POTD_FAILURE, SUBMIT_CODE_START, SUBMIT_CODE_SUCCESS, SUBMIT_CODE_FAILURE, LOGOUT, UPDATE_PROGRAM, UPDATE_PROGRAM_SUCCESS, UPDATE_PROGRAM_FAILURE, DELETE_PROGRAM, DELETE_PROGRAM_SUCCESS, DELETE_PROGRAM_FAILURE, LOAD_PRACTISE_PROBLEMS, LOAD_PRACTISE_PROBLEMS_SUCCESS, LOAD_PRACTISE_PROBLEMS_FAILURE, LOAD_LEADERBOARD, LOAD_LEADERBOARD_SUCCESS, LOAD_LEADERBOARD_FAILURE, PROFILE, PROFILE_LOAD_SUCCESS, PROFILE_LOAD_FAIURE, RUN_CODE_EDITOR, CODE_EDITOR_SUCCESS, CODE_EDITOR_FAILURE, FORGOT_PASSWORD, FORGOT_PASSWORD_FAILURE, FORGOT_PASSWORD_SUCCESS, RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE, SAVE_SNIPPET, SAVE_SNIPPET_SUCCESS, SAVE_SNIPPET_FAILURE, UPDATE_SNIPPET, UPDATE_SNIPPET_SUCCESS, CLEAR_SNIPPET, FETCH_SNIPPETS, FETCH_SNIPPETS_SUCCESS, FETCH_SNIPPETS_FAILURE, DELETE_SNIPPET, DELETE_SNIPPET_SUCCESS, DELETE_SNIPPET_FAILURE, LOAD_PROGRAM_DETAIL, LOAD_PROGRAM_DETAIL_SUCCESS, LOAD_PROGRAM_DETAIL_FAILURE} from '../constants/actionTypes';
import { executeCode, fetchBatchResults,fetchConsolidatedResults,fetchPublishedChallenges,loginUser,registerUser, setSession, uploadChallenge,updateProgram, deleteProgram, submitCode, fetchPracticePrograms, fetchLeaderBoard, fetchProfile, runProgram, forgotPassword, resetPassword, saveSnippetCode, updateSnippetCode, fetchCodeSnippets, deleteSnippet, fetchProgramDetail } from './networkmanager';
import { showError } from '../actions';

const networkMiddleware = store => next => action => {
    switch (action.type) {
        case BATCH_RESULTS_LOAD:{
            console.log("BATCH_RESULTS_LOAD action",action.url,action.htNos)
            next(action)
            fetchBatchResults(action.url,action.htNos).then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:BATCH_RESULTS_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:BATCH_RESULTS_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
               
            }).catch(error => {
                console.log(error)
                store.dispatch({type:BATCH_RESULTS_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }

        case CONSOLIDATE_RESULTS_LOAD:{
            console.log("CONSOLIDATE_RESULTS_LOAD action",action.htNo)
            next(action)
            fetchConsolidatedResults(action.htNo).then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:CONSOLIDATE_RESULTS_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:CONSOLIDATE_RESULTS_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:CONSOLIDATE_RESULTS_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }

        case LOGOUT:{
            console.log("LOGOUT action")
            next(action)
            setSession(null)
            break;
        }

        case LOGIN_USER:{
            console.log("LOGIN_USER action",action.htNo)
            next(action)
            loginUser(action.email,action.pwd).then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:LOGIN_USER_SUCCESS,payload:response})
                }else{
                    store.dispatch({type:LOGIN_USER_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:LOGIN_USER_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }

        case REGISTER_USER:{
            console.log("LOGIN_USER action",action)
            next(action)
            registerUser(action.email,action.pwd,action.collegeName,action.name).then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:REGISTER_USER_SUCCESS,payload:response.result})
                    store.dispatch(showError("Success","Registration completed. Please Login Again."))
                }else{
                    store.dispatch({type:REGISTER_USER_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:REGISTER_USER_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }

        case LOAD_PROGRAM_DETAIL:{
            console.log("LOAD_PROGRAM_DETAIL action",action)
            next(action)
            fetchProgramDetail(action.programId).then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:LOAD_PROGRAM_DETAIL_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:LOAD_PROGRAM_DETAIL_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:LOAD_PROGRAM_DETAIL_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }

        case CODE_RUNNER_START:{
            console.log("CODE_RUNNER_START action",action.langMode,action.input)
            next(action)
            executeCode(action.langMode, action.code, action.programId, action.userId, action.input).then(response => {
                console.log(response)
                if(response.status=='success'){
                    let status = response.result.status
                    if(status.id==6){
                        store.dispatch({type:CODE_RUNNER_SUCCESS,payload:response.result.compile_output})
                    }else if(status.id>=7&&status.id<=13){
                        store.dispatch({type:CODE_RUNNER_SUCCESS,payload:response.result.stderr})
                    } else if(status.id==3){
                        store.dispatch({type:CODE_RUNNER_SUCCESS,payload:response.result.stdout})
                    } else{
                        store.dispatch({type:CODE_RUNNER_SUCCESS,payload:response.result.status.description})
                    }
                }else{
                    console.log("request failed",response)
                    store.dispatch({type:CODE_RUNNER_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log("Error occured",error.response.status)
                store.dispatch({type:CODE_RUNNER_FAILURE,errorData:error})
                if(error.response.status >=400 && error.response.status <=499){
                    store.dispatch(showError("Login","Please login to execute code"))
                }else{
                    store.dispatch(showError("Error","Something went wrong.Please try again later."))
                }
                
            })
            break;
        }

        case RUN_CODE_EDITOR:{
            console.log("RUN_CODE_EDITOR action",action.langMode,action.input)
            next(action)
            runProgram(action.langMode, action.code, action.userId, action.input).then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:CODE_EDITOR_SUCCESS,payload:response.fileId})
                }else{
                    console.log("request failed",response)
                    store.dispatch({type:CODE_EDITOR_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log("Error occured",error.response.status)
                store.dispatch({type:CODE_EDITOR_FAILURE,errorData:error})
                if(error.response.status >=400 && error.response.status <=499){
                    store.dispatch(showError("Login","Please login to execute code"))
                }else{
                    store.dispatch(showError("Error","Something went wrong.Please try again later."))
                }
                
            })
            break;
        }

        case SUBMIT_CODE_START:{
            console.log("SUBMIT_CODE_START action",action.langMode,action.programId)
            next(action)
            submitCode(action.langMode, action.code,action.programId,action.userId).then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:SUBMIT_CODE_SUCCESS,payload:response.message}) 
                    store.dispatch(showError("Success",response.message))   
                }else{
                    store.dispatch({type:SUBMIT_CODE_FAILURE,errorData:response.result})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:SUBMIT_CODE_FAILURE,errorData:error})
                if(error.response.status >=400 && error.response.status <=499){
                    store.dispatch(showError("Login","Please login to execute code"))
                }else{
                    store.dispatch(showError("Error","Something went wrong.Please try again later."))
                }
            })
            break;
        }

        case UPLOAD_PROGRAMS:{
            console.log("UPLOAD_PROGRAMS action",action.payload)
            next(action)
            uploadChallenge(action.payload).then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:UPLOAD_PROGRAM_SUCCESS,payload:response.result})
                    store.dispatch(showError("Success","Program Uploaded Succesfully"))
                }else{
                    store.dispatch({type:UPLOAD_PROGRAM_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:UPLOAD_PROGRAM_FAILURE,errorData:error})
                if(error.response.status >=400 && error.response.status <=499){
                    store.dispatch(showError("Error","Please login to execute code"))
                }else{
                    store.dispatch(showError("Error","Something went wrong.Please try again later."))
                }
            })
            break;
        }

        case UPDATE_PROGRAM:{
            console.log("UPDATE_PROGRAM action",action.id,action.payload)
            next(action)
            updateProgram(action.id,action.payload).then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:UPDATE_PROGRAM_SUCCESS,payload:response.result})
                    store.dispatch(showError("Success","Updated Succesfully"))
                }else{
                    store.dispatch({type:UPDATE_PROGRAM_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:UPDATE_PROGRAM_FAILURE,errorData:error})
                if(error.response.status >=400 && error.response.status <=499){
                    store.dispatch(showError("Error","Please login to execute code"))
                }else{
                    store.dispatch(showError("Error","Something went wrong.Please try again later."))
                }            })
            break;
        }


        case DELETE_PROGRAM:{
            console.log("DELETE_PROGRAM action",action.id)
            next(action)
            deleteProgram(action.id).then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch(showError("Success","Deleted Succesfully"))
                    store.dispatch({type:DELETE_PROGRAM_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:DELETE_PROGRAM_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:DELETE_PROGRAM_FAILURE,errorData:error})
                if(error.response.status >=400 && error.response.status <=499){
                    store.dispatch(showError("Error","Please login to execute code"))
                }else{
                    store.dispatch(showError("Error","Something went wrong.Please try again later."))
                }            })
            break;
        }

        case LOAD_POTD:{
            console.log("LOAD_POTD action",action)
            next(action)
            fetchPublishedChallenges().then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:LOAD_POTD_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:LOAD_POTD_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:LOAD_POTD_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }
        case LOAD_PRACTISE_PROBLEMS:{
            console.log("LOAD_PRACTISE_PROBLEMS action",action)
            next(action)
            fetchPracticePrograms().then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:LOAD_PRACTISE_PROBLEMS_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:LOAD_PRACTISE_PROBLEMS_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:LOAD_PRACTISE_PROBLEMS_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }
        case LOAD_LEADERBOARD:{
            console.log("LOAD_LEADERBOARD action",action)
            next(action)
            fetchLeaderBoard().then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:LOAD_LEADERBOARD_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:LOAD_LEADERBOARD_FAILURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:LOAD_LEADERBOARD_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }
        case PROFILE:{
            console.log("PROFILE action",action)
            next(action)
            fetchProfile().then(response => {
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:PROFILE_LOAD_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:PROFILE_LOAD_FAIURE,errorData:response.message})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:PROFILE_LOAD_FAIURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }
        case FORGOT_PASSWORD:{
            console.log("FORGOT_PASSWORD action",action)
            next(action)
            forgotPassword(action.payload).then((response)=>{
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:FORGOT_PASSWORD_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:FORGOT_PASSWORD_FAILURE,errorData:response.result})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:FORGOT_PASSWORD_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }
        case RESET_PASSWORD:{
            console.log("RESET_PASSWORD action",action)
            next(action)
            resetPassword(action.password,action.repassword,action.token).then((response)=>{
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:RESET_PASSWORD_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:RESET_PASSWORD_FAILURE,errorData:response.result})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:RESET_PASSWORD_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }

        case SAVE_SNIPPET:{
            console.log("SAVE_SNIPPET action",action)
            next(action)
            saveSnippetCode(action.langMode,action.code,action.name,action.userId,action.tags).then((response)=>{
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:SAVE_SNIPPET_SUCCESS,payload:response.code})
                }else{
                    store.dispatch({type:SAVE_SNIPPET_FAILURE,errorData:response.result})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:SAVE_SNIPPET_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }
        case FETCH_SNIPPETS:{
            console.log("FETCH_SNIPPETS action",action)
            next(action)
            fetchCodeSnippets().then((response)=>{
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:FETCH_SNIPPETS_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:FETCH_SNIPPETS_FAILURE,errorData:response.result})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:FETCH_SNIPPETS_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }
        case UPDATE_SNIPPET:{
            console.log("UPDATE_SNIPPET action",action)
            next(action)
            updateSnippetCode(action.langMode,action.code,action.name,action.userId,action.tags,action.id).then((response)=>{
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:UPDATE_SNIPPET_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:UPDATE_PROGRAM_FAILURE,errorData:response.result})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:UPDATE_PROGRAM_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }
        case DELETE_SNIPPET:{
            console.log("DELETE_SNIPPET action",action)
            next(action)
            deleteSnippet(action.id).then((response)=>{
                console.log(response)
                if(response.status=='success'){
                    store.dispatch({type:DELETE_SNIPPET_SUCCESS,payload:response.result})
                }else{
                    store.dispatch({type:DELETE_SNIPPET_FAILURE,errorData:response.result})
                    store.dispatch(showError("Error",response.result))
                }
            }).catch(error => {
                console.log(error)
                store.dispatch({type:DELETE_SNIPPET_FAILURE,errorData:error})
                store.dispatch(showError("Error",error.message))
            })
            break;
        }
        case CLEAR_SNIPPET:{
            console.log("CLEAR_SNIPPET action",action)
            next(action)
            break;
        }
        default:next(action);
                
    }
}

export { networkMiddleware }