import { LOAD_POTD,LOAD_POTD_SUCCESS,LOAD_POTD_FAILURE,UPDATE_PROGRAM, DELETE_PROGRAM, UPDATE_PROGRAM_SUCCESS, UPDATE_PROGRAM_FAILURE, DELETE_PROGRAM_FAILURE, DELETE_PROGRAM_SUCCESS} from "../../constants/actionTypes";

const initialState = {
    loading : false,
    programs:[]
};
    
function publishedChallengesReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_POTD:  return { ...state, loading: true }
        case UPDATE_PROGRAM,DELETE_PROGRAM: return { ...state, loading: true }
        case LOAD_POTD_SUCCESS: {
            console.log("LOAD_POTD action",action.payload)
            return { ...state, programs:action.payload, loading: false}
        }
        case LOAD_POTD_FAILURE,UPDATE_PROGRAM_FAILURE,DELETE_PROGRAM_FAILURE: return { ...state,loading: false}
        case UPDATE_PROGRAM_SUCCESS: {
            console.log("UPDATE_PROGRAM_SUCCESS action",action.payload)
            const id = action.payload.result._id
            let programs = state.programs
            const index = programs.findIndex(item => item._id==id);
            programs[index] = action.payload.result

            return { ...state,loading: false,programs:programs}
        }
        case DELETE_PROGRAM_SUCCESS: {
            const id = action.payload.id
            let programs = state.programs
            const index = programs.findIndex(item => item._id==id);
            if (index > -1) { 
                programs.splice(index, 1);
            }
            console.log("DELETE_PROGRAM_SUCCESS action",action.payload," index ",index)
            return { ...state, loading: false,programs:programs}
        }
        default:return state;
    }
};
        
export default publishedChallengesReducer;