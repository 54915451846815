import SimpleCard from '../component/SimpleCard'
import {
    Button,
    Grid,
    Backdrop,
    CircularProgress,
    Typography,
    Tooltip, 
    TableRow,
    MenuItem,
    Icon, 
    IconButton,
    Fab,
} from '@mui/material'
import { Box,styled } from '@mui/system'
import { ValidatorForm, TextValidator,SelectValidator } from 'react-material-ui-form-validator'
import React, { useState, useEffect ,Fragment} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { uploadProgram,showError,closeError, updateProgram } from '../actions'
import { ErrorDialog } from '../component/ErrorDialog'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState,convertToRaw  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {convertFromHTML,ContentState} from 'draft-js';
import { useLocation } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate  } from 'react-router-dom'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
  }))
  
const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
  }))
  
const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
  }))
  
const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(5)
  }))

  const ProgramBox = styled(Box)(({ theme }) => ({
    cursor: 'pointer',
  }))

  function UserProfile() {

    const dispatch = useDispatch();

    const navigate = useNavigate();
    
    const loading = useSelector((state) => state.user.loading);
  
    const [confirm,showConfirm] = useState(false)

    const [index,setIndex] = useState(-1)

    const profile = useSelector((state) => state.user.profile) ;

    const user = useSelector((state) => state.user.instance);

    const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

    function formatDate(date) {
      var d = new Date(date)
      var month = ''+monthNames[d.getMonth()]
      var day = '' + d.getDate()
      var year = d.getFullYear();

      if (day.length < 2) 
          day = '0' + day;
  
      return day+" "+month
  }

  const solveChallenge = (index)=>{
    console.log("Solve Challenge")
    navigate('/coding/solveproblem',{ state:{program: profile.userPrograms[index].program_info ,user:user,recents:true,programCode:profile.userPrograms[index].challenge_saved_code}})
}

    const getProgramsList = (programs)=>{

      let rows = programs.map((program,index)=>{
          return <>
            <ProgramBox sx={{ display: 'flex',my:3  }} onClick={()=>solveChallenge(index)}>
              <Box sx={{justifyContent:'center',alignContent:'center',margin:'auto'}}>
                <Box sx={{borderRadius:'1rem',justifySelf:'center',backgroundColor:'blue',width:'2rem',height:'2rem',my:'auto',mx:2,textAlign:'center'}}>
                    {
                      program.program_info.programType=='challenge'?<Typography sx={{fontSize:'1.2rem',color:'white'}}>C</Typography>:<><Typography sx={{fontSize:'1.2rem',height:'100%',color:'white'}}>P</Typography></>
                    }
                </Box>
              </Box>
              <Box sx={{ flexGrow: 1 }}> 
                  <Typography sx={{fontSize:'1.4rem',mt:2}}>{program.program_info.programTitle}</Typography>
                  <span style={{fontSize:'1.rem',marginBottom:'10px'}}>{formatDate(Date.parse(program.code_submission_date))} | </span><span style={{padding:'3px',fontSize:'1.rem',textAlign:'center',marginBottom:'10px',borderRadius:'4px'}}>{program.program_info.complexity} </span><span style={{fontSize:'1.rem',marginBottom:'10px'}}>  |  Points:{program.points_awarded}</span>
              </Box>
              <Box sx={{my:'auto',mx:2}}>
              {program.challenge_status =="accepted"?<span style={{padding:'3px',fontSize:'1.5rem',backgroundColor:'green',color:'white',textAlign:'center',marginBottom:'10px',borderRadius:'4px'}}> Executed</span>:<span style={{padding:'3px',fontSize:'1.5rem',backgroundColor:'red',color:'white',textAlign:'center',marginBottom:'10px',borderRadius:'4px'}}>Error </span>}
              </Box>
            </ProgramBox>
              <hr class="solid"></hr>
              </>
      })

      return (<>
          {
             rows 
          }
      </>)
  }

    return (
        <Container>
             <Box sx={{mt:3,mb:3}}>
                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.8rem'}}>Profile</Typography>
            </Box>

      <SimpleCard className="card" sx= {{my:3}}>
              <JustifyBox sx={{mt:3,mb:3,display:'flex',flexDirection: 'column',}}>
                  <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'3rem'}}>{profile.name}</Typography>
                  {profile.user_badge?<Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.2rem'}}>{profile.user_badge}</Typography>:<></> }
                  {profile.joinedDate?<Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1rem',color:'green'}}>joined on {formatDate(Date.parse(profile.joinedDate))}</Typography>:<></> }

              </JustifyBox>

              <Box sx={{ display:'flex',flexDirection: 'row',justifyContent: 'space-around' }}>
                    <Box>
                      <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'2rem'}}>{profile.solvedProgramCount}</Typography>
                      <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.2rem'}}>Solved Programs</Typography>
                    </Box>
                    <Box>
                      <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'2rem'}}>{profile.weeklyWinnerCount}</Typography>
                      <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.2rem'}}>Trophies Won</Typography>
                    </Box>
                    <Box>
                      <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'2rem'}}>{profile.totalPointsEarned}</Typography>
                      <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.2rem'}}>Points Earned</Typography>
                    </Box>
              </Box>

              <Box sx={{mt:5,mb:3,border:'1',borderColor: 'grey.500',borderRadius: 1,p:2}}>
                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.6rem'}}>Recent Activity</Typography>

                {
                  (profile.userPrograms!=null&&profile.userPrograms.length>0)?getProgramsList(profile.userPrograms):<><JustifyBox sx={{mt:3,mb:3}}>No activity found..</JustifyBox></>
                }
            </Box>

      </SimpleCard>
      <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
        <CircularProgress color="inherit" sx={{mx:2}}/>
        <div>
          <h2>Please wait...</h2>
        </div>
    </Backdrop>
      </Container>)
  }

  export default UserProfile;
