import { UPLOAD_PROGRAMS,BATCH_RESULTS_LOAD, CANCEL_ERROR, SHOW_ERROR,CONSOLIDATE_RESULTS_LOAD, CODE_RUNNER_START, LOGIN_USER, REGISTER_USER,LOAD_POTD, SUBMIT_CODE_START, LOGOUT, UPDATE_PROGRAM, DELETE_PROGRAM, LOAD_PRACTISE_PROBLEMS, LOAD_LEADERBOARD, PROFILE, RUN_CODE_EDITOR, FORGOT_PASSWORD, RESET_PASSWORD, SAVE_SNIPPET, UPDATE_SNIPPET, DELETE_SNIPPET, CLEAR_SNIPPET, FETCH_SNIPPETS, SET_CODE_SNIPPET, LOAD_PROGRAM_DETAIL } from "../constants/actionTypes"

export const loadBatchResult = (url,htNos) => {
    return {
      type: BATCH_RESULTS_LOAD,
      url:url,
      htNos: htNos
    }
}

export const loadConsolidateResult = (htNo) => {
  return {
    type: CONSOLIDATE_RESULTS_LOAD,
    htNo: htNo
  }
}

export const executeCodeResult = (langMode,code,programId,userId,input) => {
  return {
    type: CODE_RUNNER_START,
    langMode: langMode,
    code:code,
    input:input,
    programId:programId,
    userId:userId
  }
}

export const submitCodeResult = (langMode,code,programId,userId) => {
  return {
    type: SUBMIT_CODE_START,
    langMode: langMode,
    code:code,
    programId:programId,
    userId:userId
  }
}

export const runCodeEditor = (langMode,code,userId,input) => {
  return {
    type: RUN_CODE_EDITOR,
    langMode: langMode,
    code:code,
    userId:userId,
    input:input,
  }
}

export const showError = (heading,message) => {
  return {
    type: SHOW_ERROR,
    heading:heading,
    message:message
  }
}

export const closeError = () => {
  return {
    type: CANCEL_ERROR
  }
}

export const loginUser = (email,pwd) => {
  return {
    type: LOGIN_USER,
    email: email,
    pwd:pwd
  }
}

export const logout = () => {
  return {
    type: LOGOUT
  }
}

export const profile = () => {
  return {
    type: PROFILE
  }
}


export const registerUser = (email,pwd,collegeName,name) => {
  return {
    type: REGISTER_USER,
    email: email,
    pwd:pwd,
    collegeName:collegeName,
    name:name
  }
}

export const uploadProgram = (programDetails) => {
  return {
    type: UPLOAD_PROGRAMS,
    payload:programDetails
  }
}

export const loadProgramDetail = (programId) => {
  return {
    type: LOAD_PROGRAM_DETAIL,
    programId:programId
  }
}

export const updateProgram = (programId,programDetails) => {
  return {
    type: UPDATE_PROGRAM,
    id:programId,
    payload:programDetails
  }
}

export const deleteProgram = (programId) => {
  return {
    type: DELETE_PROGRAM,
    id:programId
  }
}


export const fetchChallengePrograms = () => {
  return {
    type: LOAD_POTD
  }
}

export const fetchPracticePrograms = () => {
  return {
    type: LOAD_PRACTISE_PROBLEMS
  }
}

export const loadLeaderBoard = () => {
  return {
    type: LOAD_LEADERBOARD
  }
}

export const forgotPassword = (email) =>{
  return{
    type:FORGOT_PASSWORD,
    payload:email
  }
 }

 export const resetPassword = (password,repassword,token) =>{
  return{
    type:RESET_PASSWORD,
    password:password,
    repassword:repassword,
    token:token
  }
}

  export const saveSnippet = (langMode,name,code,userId,tags) =>{
    return{
      type:SAVE_SNIPPET,
      langMode:langMode,
      name:name,
      code:code,
      userId:userId,
      tags:tags
    }
 }

 export const updateSnippet = (langMode,name,code,userId,tags,snippetId) =>{
  return{
    type:UPDATE_SNIPPET,
    langMode:langMode,
    name:name,
    code:code,
    userId:userId,
    tags:tags,
    id:snippetId
  }
}

export const deleteSnippet = (snippetId) =>{
  return{
    type:DELETE_SNIPPET,
    id:snippetId
  }
}

export const clearSnippet = () =>{
  return{
    type:CLEAR_SNIPPET,
  }
}

export const fetchSnippets = () =>{
  return{
    type:FETCH_SNIPPETS,
  }
}

export const setCodeSnippet = (snippet) =>{
  return{
    type:SET_CODE_SNIPPET,
    payload:snippet
  }
}

