import SimpleCard from '../component/SimpleCard'
import {
    Button,
    Grid,
    Backdrop,
    CircularProgress,
    Typography,
    Tooltip, 
    TableRow,
    MenuItem,
    Icon, 
    IconButton,
    Fab,
} from '@mui/material'
import { Box,styled } from '@mui/system'
import { ValidatorForm, TextValidator,SelectValidator } from 'react-material-ui-form-validator'
import React, { useState, useEffect ,Fragment} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { uploadProgram,updateProgram } from '../actions'
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState,convertToRaw  } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import {convertFromHTML,ContentState} from 'draft-js';
import { useLocation } from "react-router-dom";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
  }))
  
const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
  }))
  
const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
  }))
  
const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(5)
  }))
  

  function UploadProgram() {

    const dispatch = useDispatch();

    const location  = useLocation();
    
    const [programMeta, setProgramMeta] = useState({
        programType:'',
        complexity:'',
        title:'',
        description:'',
        solutionCode:'',
        companyTags:'',
        programTags:''
      });

    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const [solutionState, setSolutionState] = useState(EditorState.createEmpty())


    const [editMode, setEditMode] = useState(false)

    const [params,setParams] = useState({
        inputs:[{
                varName:'',
                dataType:'',
                index:0,
                arrayDimens:0
                }],
                output:{
                varName:'',
                dataType:'',
                arrayDimens:0
                }
                })

    const [testcases,setTestcases] = useState({
        
        values:[{
        inputs:[{
            varName:'',
            dataType:'',
            index:0,
            arrayDimens:0,
            value:0
            }],
        output:{ 
            varName:'',
            dataType:'',
            arrayDimens:0,
            value:0
        }
    }]
})

    useEffect(() => {
        console.log('program meta loaded',location.state)

        if(location.state!=null&&location.state.programMeta!=null){
            const meta = location.state.programMeta;
            setProgramMeta({
                programType:meta.status=='practice'?'practice':'challenge',
                complexity:meta.complexity,
                title:meta.programTitle,
                execTestCases:meta.execTestCases,
                expOutCome:meta.execOutput,
                solutionCode:meta.programCode,
                companyTags:meta.companyAsked,

            })

            const blocksFromHTML = convertFromHTML(meta.programDesc);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );

            setEditorState(EditorState.createWithContent(state));

            const solDescFromHTML = convertFromHTML(meta.solutionDesc);
            const solState = ContentState.createFromBlockArray(
                solDescFromHTML.contentBlocks,
                solDescFromHTML.entityMap,
            );

            setSolutionState(EditorState.createWithContent(solState));
            
            setEditMode(location.state.editMode)
        }
    },[])


    const loading = useSelector((state) => state.uploadProgram.loading);
  
    const error = useSelector((state) => state.error.error);
  
    const status = useSelector((state) => state.uploadProgram.status) ;
  
    const handleFormSubmit = (event) => {
      event.preventDefault();
      let inputs = params.inputs.map((input,index)=>{
        input.index = index
        return input
      })

      let nTestcases = testcases.values.map((testcase,index)=>{
        testcase.testcaseNum = index

        let inputs = testcase.inputs.map((input,mindex)=>{
            input.index = mindex
            return input
          })

        testcase.inputs = inputs

        return testcase
      })

      if(editMode){
        const id = location.state.programMeta._id
        dispatch(updateProgram(id,{...programMeta,description:draftToHtml(convertToRaw(editorState.getCurrentContent())),solutionDesc:draftToHtml(convertToRaw(solutionState.getCurrentContent())),programType:location.state.programMeta.status,inputs:JSON.stringify(inputs),output:JSON.stringify(params.output),testcases:JSON.stringify(nTestcases)}))
      }else{
        dispatch(uploadProgram({...programMeta,description:draftToHtml(convertToRaw(editorState.getCurrentContent())),solutionDesc:draftToHtml(convertToRaw(solutionState.getCurrentContent())),inputs:JSON.stringify(inputs),output:JSON.stringify(params.output),testcases:JSON.stringify(nTestcases)}))
      }
    }

    const handleChange = (name,event) => {
        console.log("Selected Value",event.target.value)
        let temp = {...programMeta}
        temp[name]=event.target.value
        setProgramMeta({...temp})
    }

    const handleFieldMapChange = ({ target: { name, value } })  =>{
        let temp = {...programMeta}
        temp[name]=value
        setProgramMeta({...temp})
    }

    const addParams = ()=>{
        const fields = params.inputs

        fields.push({varName:'',
        dataType:'',
        arrayDimens:0})

        setParams({...params,inputs:fields})

        let inputs = fields.map((field)=>{
            return {...field,value:0}
        })

        let output = {...params.output,value:0}

        setTestcases({...testcases,values:[{inputs:inputs,output:output}]})
    }

    const addTestCases = ()=>{
        const fields = params.inputs

        let inputs = fields.map((field)=>{
            return {...field,value:0}
        })

        let output = {...params.output,value:0}

        const values = testcases.values

        values.push({inputs:inputs,output:output})

        setTestcases({...testcases,values:values})
    }

    const removeTestCases = (index) =>{
        const fields = testcases.values

        if(index!==-1){
            fields.splice(index,1)
        }

        setTestcases({...params,values:fields})
    }

    const removeParams = (index)=>{
        const fields = params.inputs

        if(index!==-1){
            fields.splice(index,1)
        }

        setParams({...params,inputs:fields})

        let inputs = fields.map((field)=>{
            return {...field,value:0}
        })

        let output = {...params.output,value:0}

        setTestcases({...testcases,values:[{inputs:inputs,output:output}]})
    }

    const handleStaffTextChange = ({ target: { name, value } }) => {
        console.log("Selected staff item",params.inputs)
        let fields = params.inputs
        const staffVars = name.split('_')
        if(staffVars[0]=='input'){
            fields[parseInt(staffVars[2])][staffVars[1]] = value
            console.log("Selected Input fields item",fields)
            setParams({...params,inputs:fields})
        }else if (staffVars[0]=='output'){
            fields = params.output
            fields[staffVars[1]] = value
            console.log("Selected Output fields item",fields)
            setParams({...params,output:fields})
        }

        let inputs = params.inputs.map((field)=>{
            return {...field,value:0}
        })

        let output = {...params.output,value:0}

        setTestcases({...testcases,values:[{inputs:inputs,output:output}]})
       
    }

    const handleTestCasesTextChange = ({ target: { name, value } }) => {
        let fields = testcases.values
        const staffVars = name.split('_')
        if(staffVars[0]=='input'){
            fields[parseInt(staffVars[1])].inputs[parseInt(staffVars[2])].value = value
            console.log("Selected Input fields item",fields)
            setTestcases({...testcases,values:fields})
        }else if (staffVars[0]=='output'){
            fields[parseInt(staffVars[1])].output.value = value
            console.log("Selected Input fields item",fields)
            setTestcases({...testcases,values:fields})

        }
    }

    const onEditorStateChange = (editorState)=>{
        setEditorState(editorState)
    }

    const onSolutionStateChange = (editorState)=>{
        setSolutionState(editorState)
    }

    return (
        <Container>
             <Box sx={{mt:3,mb:3}}>
                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.8rem'}}>Upload Program</Typography>
            </Box>

      <SimpleCard className="card" sx= {{my:3}}>
            <ValidatorForm onSubmit={handleFormSubmit}>
            <Grid container>
                    <Grid item lg={6} md={6} sm={6} xs={12} sx={{px:3}}>
                        <SelectValidator
                            name="programType"
                            label="Program Type"
                            disabled={editMode?true:false}
                            sx={{ my:2,width:'100%'}}
                            value={programMeta.programType}
                            errorMessages={['this field is required']}
                            validators={['required']}
                            onChange={(e) => handleChange('programType',e)}>
                            <MenuItem value="practice">Practice</MenuItem>
                            <MenuItem value="challenge">Challenge</MenuItem>
                        </SelectValidator>
                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} sx={{px:3}}>
                        <SelectValidator
                                name="complexity"
                                label="Complexity"
                                sx={{ my:2,width:'100%'}}
                                value={programMeta.complexity}
                                errorMessages={['this field is required']}
                                validators={['required']}
                                onChange={(e) => handleChange('complexity',e)}>
                                <MenuItem value="easy">Easy</MenuItem>
                                <MenuItem value="medium">Medium</MenuItem>
                                <MenuItem value="hard">Hard</MenuItem>
                            </SelectValidator>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{px:3}}>
                        <TextValidator
                            type="text"
                            name="title"
                            label="Program Title"
                            value={programMeta.title}
                            validators={['required']}
                            sx={{my:2,mr:3,width:'100%'}}
                            onChange={handleFieldMapChange}
                            errorMessages={['this field is required']}
                        />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{px:3}}>
                        <Editor
                            placeholder="Program Description"
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onEditorStateChange}
                            />
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{px:3}}>
                        <JustifyBox sx={{mt:3,mb:3}}>
                            <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.2rem'}}>Input Parameters</Typography>
                        </JustifyBox>
                        </Grid>
                        <Grid container>
                        {
                            params.inputs.map((item,index) => (
                                <>
                                <Grid item lg={1} md={1} sm={1} xs={1} sx={{px:3,py:2}}>
                                    <p>{index}</p>
                                </Grid>
                                <Grid item lg={4} md={4} sm={4} xs={12} sx={{pe:3}}>
                                        <TextValidator
                                            type="text"
                                            id={item.varName}
                                            label="Variable Name"
                                            value={item.varName}
                                            name={"input_"+"varName_"+index}
                                            onChange={handleStaffTextChange}
                                            sx={{my:2,mr:3,width:'100%'}}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            />
                                    </Grid>
                                    <Grid item lg={3} md={3} sm={3} xs={12} sx={{px:3}}>
                                        <SelectValidator
                                            id={item.dataType}
                                            name={"input_"+"dataType_"+index}
                                            label="Select DataType"
                                            onChange={handleStaffTextChange}
                                            value={item.dataType}
                                            sx={{my:2,mr:3,width:'100%'}}
                                            errorMessages={['this field is required']}
                                            validators={['required']}>

                                                <MenuItem value='integer'>Integer</MenuItem>
                                                <MenuItem value='float'>Float</MenuItem>
                                                <MenuItem value='string'>String</MenuItem>
                                                <MenuItem value='char'>Char</MenuItem>

                                        </SelectValidator>
                                
                                    </Grid>

                                    <Grid item lg={3} md={3} sm={3} xs={12} sx={{px:3}}>
                                        <SelectValidator
                                            id={item.arrayDimens}
                                            name={"input_"+"arrayDimens_"+index}
                                            label="Select Array Dimens"
                                            onChange={handleStaffTextChange}
                                            value={item.arrayDimens}
                                            sx={{my:2,mr:3,width:'100%'}}
                                            errorMessages={['this field is required']}
                                            validators={['required']}>

                                                <MenuItem value='0'>0</MenuItem>
                                                <MenuItem value='1'>1</MenuItem>
                                                <MenuItem value='2'>2</MenuItem>

                                        </SelectValidator>
                                
                                    </Grid>

                                    <Grid item lg={1} md={1} sm={1} xs={12} sx={{px:3}}>
                                        <DeleteIcon  id={item.minus} onClick={() => removeParams(index)} sx={{my:3,mr:3}} className="button" aria-label="Delete">
                                        </DeleteIcon>
                                    </Grid>
                                </>
                            ))
                        }
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{px:3}}>
                            <JustifyBox>
                                <Button color="secondary" onClick={() => addParams()}  aria-label="Add" className="button" variant="contained" startIcon={<AddIcon />}>
                                    Parameters
                                </Button>
                            </JustifyBox>
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{px:3,my:2}}>
                            <JustifyBox sx={{mt:3,mb:3}}>
                                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.2rem'}}>Return Value</Typography>
                            </JustifyBox>
                        </Grid>
                        <Grid container>
                                    <Grid item lg={4} md={4} sm={4} xs={12} sx={{px:3}}>
                                        <TextValidator
                                            type="text"
                                            id={params.output.varName}
                                            label="Variable Name"
                                            value={params.output.varName}
                                            name={"output_"+"varName"}
                                            onChange={handleStaffTextChange}
                                            sx={{my:2,mr:3,width:'100%'}}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            />
                                
                                    </Grid>
                                    <Grid item lg={4} md={4} sm={4} xs={12} sx={{px:3}}>
                                        <SelectValidator
                                            name={"output_dataType"}
                                            label="Select DataType"
                                            onChange={handleStaffTextChange}
                                            value={params.output.dataType}
                                            sx={{my:2,mr:3,width:'100%'}}
                                            errorMessages={['this field is required']}
                                            validators={['required']}>

                                                <MenuItem value='integer'>Integer</MenuItem>
                                                <MenuItem value='float'>Float</MenuItem>
                                                <MenuItem value='string'>String</MenuItem>
                                                <MenuItem value='char'>Char</MenuItem>
                                                <MenuItem value='boolean'>Boolean</MenuItem>

                                        </SelectValidator>
                                
                                    </Grid>

                                    <Grid item lg={4} md={4} sm={4} xs={12} sx={{px:3}}>
                                        <SelectValidator
                                            name={"output_arrayDimens"}
                                            label="Select Array Dimens"
                                            onChange={handleStaffTextChange}
                                            value={params.output.arrayDimens}
                                            sx={{my:2,mr:3,width:'100%'}}
                                            errorMessages={['this field is required']}
                                            validators={['required']}>

                                                <MenuItem value='0'>0</MenuItem>
                                                <MenuItem value='1'>1</MenuItem>
                                                <MenuItem value='2'>2</MenuItem>

                                        </SelectValidator>
                                
                                    </Grid>
                        </Grid>

                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{px:3,my:2}}>
                            <JustifyBox sx={{mt:3,mb:3}}>
                                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.2rem'}}>Test Cases</Typography>
                            </JustifyBox>
                        </Grid>
                        <Grid container>
                        {
                            testcases.values.map((item,tIndex) => (
                                <>
                                {
                                    item.inputs.map((input,index)=>{
                                        let size = 11/(item.inputs.length+1)   
                                        console.log("size of grid",size)                                    
                                        return  <Grid item lg={size} md={size} sm={size} xs={12} sx={{px:3,py:2}}>
                                        <TextValidator
                                            type="text"
                                            id={input.varName}
                                            label={"Parameter "+index}
                                            value={input.value}
                                            name={"input_"+tIndex+"_"+index}
                                            onChange={handleTestCasesTextChange}
                                            sx={{my:2,mx:2,width:'100%'}}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            />
                                        </Grid>
                                    })

                                }

                                {
                                  <Grid item lg={11/(item.inputs.length+1)} md={11/(item.inputs.length+1)} sm={11/(item.inputs.length+1)} xs={12} sx={{px:3,py:2}}>

                                        <TextValidator
                                            type="text"
                                            id={item.output.varName}
                                            label={"Output"}
                                            value={item.output.value}
                                            name={"output_"+tIndex+"_"+0}
                                            onChange={handleTestCasesTextChange}
                                            sx={{my:2,mx:2,width:'100%'}}
                                            validators={['required']}
                                            errorMessages={['this field is required']}
                                            />
                                        </Grid>
                                }
                                    <Grid item lg={1} md={1} sm={1} xs={12} sx={{px:3,py:2}}>
                                        <DeleteIcon  id={item.minus} onClick={() => removeTestCases(tIndex)} sx={{my:3,mr:3}} className="button" aria-label="Delete">
                                        </DeleteIcon>
                                    </Grid>
                                </>
                            ))
                        }
                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{px:3}}>
                            <JustifyBox>
                                <Button color="secondary" onClick={() => addTestCases()}  aria-label="Add" className="button" variant="contained" startIcon={<AddIcon />}>
                                    Testcases
                                </Button>
                            </JustifyBox>
                        </Grid>
                    
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{px:3}}>

                        <Editor
                            placeholder="Solution Description"
                            editorState={solutionState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={onSolutionStateChange}
                            />

                        </Grid>
                        <Grid item lg={12} md={12} sm={12} xs={12} sx={{px:3}}>

                            <TextValidator
                                type="text"
                                name="solutionCode"
                                multiline
                                rows={4}
                                label="Solution Code"
                                value={programMeta.solutionCode}
                                sx={{my:2,mr:3,width:'100%'}}
                                onChange={handleFieldMapChange}
                            />
                        </Grid>
                <Grid item lg={6} md={6} sm={6} xs={12} sx={{px:3}}>
                        <TextValidator
                            type="text"
                            name="companyTags"
                            label="Companies"
                            value={programMeta.companyTags}
                            sx={{my:2,mr:3,width:'100%'}}qqq
                            onChange={handleFieldMapChange}
                            errorMessages={['this field is required']}
                        />
                        </Grid>
                        <Grid item lg={6} md={6} sm={6} xs={12} sx={{px:3}}>
                        <TextValidator
                            type="text"
                            name="programTags"
                            label="Program Tags"
                            value={programMeta.programTags}
                            sx={{my:2,mr:3,width:'100%'}}
                            onChange={handleFieldMapChange}
                            errorMessages={['this field is required']}
                        />
                        </Grid>
                        </Grid>

                        <JustifyBox>
                            <StyledButton  style={{borderRadius:25, width:'50%',fontWeight:400,fontSize:'1rem'}} variant="contained" color="primary" type="submit">{editMode?"Update":"Upload"}</StyledButton>  
                        </JustifyBox>
            </ValidatorForm>
            </SimpleCard>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={loading}>

                <CircularProgress color="inherit" sx={{mx:2}}/>
                <div>
                <h2>Please wait uploading...</h2>
                </div>
            </Backdrop>

            {/* {
                error ? <ErrorDialog show={error}/>:<></>
            } */}
        </Container>
    )
}

export default UploadProgram;


