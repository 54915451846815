import { Typography } from  '@mui/material'
import SimpleCard from '../component/SimpleCard'
import {
    Button,
    Backdrop,
    CircularProgress,
} from '@mui/material'
import { Box,styled } from '@mui/system'
import React, { useState, useEffect ,Fragment,useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showError,closeError, deleteProgram, fetchPracticePrograms } from '../actions'
import { ErrorDialog } from '../component/ErrorDialog'
import { useNavigate  } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from '../component/ConfimationDialog'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
}))
  
const FlexBox = styled(Box)(() => ({
display: 'flex',
alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
justifyContent: 'center',
}))

const StyledButton = styled(Button)(({ theme }) => ({
margin: theme.spacing(5)
}))


function PracticePrograms(){

    const dispatch = useDispatch();

    const navigate = useNavigate();
    
    const loading = useSelector((state) => state.practice.loading);
  
    const [confirm,showConfirm] = useState(false)

    const [index,setIndex] = useState(-1)

    const programs = useSelector((state) => state.practice.programs) ;

    const user = useSelector((state) => state.user.instance);

    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

    function formatDate(date) {
        var d = new Date(date)
        var month = ''+monthNames[d.getMonth()]
        var day = '' + d.getDate()
        var year = d.getFullYear();

        if (day.length < 2) 
            day = '0' + day;
    
        return day+" "+month
    }

    const solveChallenge = (index)=>{
        console.log("Solve Challenge")
        navigate('/solveproblem/'+programs[index].programId,{ state:{user:user}})
    }

    const editChallenge = (index)=>{
        console.log("Edit Challenge",index)
        navigate('/coding/upload',{ state:{programMeta: programs[index].programId,editMode:true }})
    }

    const deleteChallenge = (index)=>{
      setIndex(index)
      showConfirm(true)
    }

    const confirmDelete = useCallback(() => {
        console.log("Delete Challenge",programs[index])
        dispatch(deleteProgram(programs[index]._id))
        showConfirm(false)
    })

    const closeDialog = useCallback(() => {
        showConfirm(false)
    })


    const getBackgroundColor = (complexity)=>{
        if(complexity=='easy'){
            return 'green'
        }else if(complexity=='medium'){
            return 'blue'
        }else{
            return 'red'
        }
    }

    const getProgramsList = (programs)=>{

        let rows = programs.map((program,index)=>{
            return <><Box sx={{ display: 'flex',my:3 }}>
                <Box sx={{ flexGrow: 1 }}> 
                    <span style={{fontSize:'1.rem',marginBottom:'10px'}}>{formatDate(Date.parse(program.dateAdded))} | </span><span style={{padding:'5px',fontSize:'.8rem',backgroundColor:getBackgroundColor(program.complexity),color:'white',textAlign:'center',marginBottom:'10px',borderRadius:'4px'}}>{program.complexity}</span>              

                    <Typography sx={{fontSize:'1.5rem',mt:2}}>{program.programTitle}</Typography>
                </Box>
                {
                     user!=null&&user.userType=='admin'? <Box>
                        <EditIcon sx={{ fontSize:'2rem',my:3,p:0.75,color:'white',backgroundColor: "brown",borderRadius:'5px',cursor:'pointer'}} onClick={()=>{editChallenge(index)}}/>
                        <DeleteIcon sx={{ fontSize:'2rem',my:3,p:0.75,color:'white',backgroundColor: "red",borderRadius:'5px',mx:2,cursor:'pointer'}} onClick={()=>{deleteChallenge(index)}}/>
                     </Box>:<></>
                }
                               
                <Box>
                    <Button variant="contained" color="primary" sx={{p:3,fontWeight:500,maxHeight:'2rem',mt:2}} onClick={()=>solveChallenge(index)}>Solve Problem</Button>
                </Box>
                
                </Box>
                <hr class="solid"></hr>
                </>
        })

        return (<SimpleCard sx={{mt:3}}>
            {
               rows 
            }
        </SimpleCard>)
    }

    useEffect(() => {
        if(programs==null || programs.length==0){
            dispatch(fetchPracticePrograms())
        }
    },[])

    useEffect(() => {
        console.log("loading value",loading)
    },[loading])

    return (
        <Container>
             {/* <Box sx={{mt:3,mb:3}}>
                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.8rem'}}>Practise Programs</Typography>
            </Box> */}
            {
                programs!=null && programs.length>0?getProgramsList(programs):<></>
            }

            {
                confirm ? <ConfirmationDialog show={confirm} confirmCall={confirmDelete} closeCall={closeDialog} heading="Delete" message="Confirm Delete?"/>:<></>
            }

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
        <CircularProgress color="inherit" sx={{mx:2}}/>
        <div>
          <h2>Please wait while loading programs...</h2>
        </div>
    </Backdrop>
</Container>)
}

export default PracticePrograms