import AceEditor from "react-ace";
import { Menu, Tooltip, Typography } from  '@mui/material'
import SimpleCard from '../component/SimpleCard'
import { ValidatorForm, TextValidator,SelectValidator } from 'react-material-ui-form-validator'
import {
    Button,
    MenuItem,
    Table,
    Backdrop,
    Grid,
    CircularProgress,
} from '@mui/material'
import { Box,styled } from '@mui/system'
import React, { useState, useEffect ,Fragment,useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools"
import { clearSnippet, deleteSnippet, executeCodeResult, fetchSnippets, runCodeEditor, saveSnippet, setCodeSnippet, showError, updateSnippet } from "../actions";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import IconButton from '@mui/material/IconButton';
import { EditBoxDialog } from "../component/EditNameDialog";
import langMap from '../utils/languages.json';
import { AddRounded, Clear, FlareSharp, PlayArrow, PlayArrowRounded, SaveAsRounded,ArrowOutward } from "@mui/icons-material";
import TerminalComponent from "../component/TerminalComponent";
import MenuIcon from '@mui/icons-material/Menu';
import { slide as SideMenu } from 'react-burger-menu';
import './Sidebar.css';
import { LoginDialog } from "../component/LoginDialog";
import { useNavigate } from "react-router";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Link } from "react-router-dom";
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import SmartDisplayIcon from '@mui/icons-material/SmartDisplay';
// const Container = styled('div')(({ theme }) => ({
//   }))

const Container = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        margin: '2px',
    },
  }))

const CodeBox = styled('div')(({ theme }) => ({
backgroundColor: '#E7E9EB',
width: '100%',
overflow: 'auto',
position: 'absolute',
top: '105px',
bottom: 0,
height: 'auto'
}))

const HeaderBox = styled('div')(({theme})=>({
    height: '58px',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    top: '85px',
    display:'flex',
    flexDirection:'row'
}))
  
  const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
  }))
  
  const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
  }))

  const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
  };
  
  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };
  
  const Tab = styled(TabUnstyled)`
    color: ${grey[500]};
    cursor: pointer;
    font-size: 0.8em;
    font-weight: 500;
    background-color: transparent;
    display: flex;
    justify-content: center;
    padding:10px;
    &:focus {
      color: #fff;
    }
    outline: none;
    border:0.5px solid ${grey[200]};
  
    &.${tabUnstyledClasses.selected} {
      background-color: ${blue[600]};
      color: #fff;
      outline: none;
      border:0.5px solid ${blue[200]};
    }
  
    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;
  
  const TabPanel = styled(TabPanelUnstyled)(
    ({ theme }) => `
    width: 100%;
    height:100%;
    font-size: 1rem;
    padding: 2px 2px;
    overflow:'auto',
    height:'100%',
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    `,
  );
  
  const TabsList = styled(TabsListUnstyled)(
    ({ theme }) => `
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    overflow:'auto';
    `,
  );

function MobileEditor() {

    const javaCode = `\nclass Program{ 
    public static void main(String args[]){
        System.out.println("hello");
    }
}`;

let defaultResult = {
    mode:62,
    code:javaCode,
    stdinput:'',
    editorMode:'java',
    fileName:'Main.java'
    };

    const dispatch = useDispatch();

    const error = useSelector((state) => state.error.error);
  
    const fileId = useSelector((state) => state.codeRunner.fileId) ;

    const snippetLoading = useSelector((state) => state.snippets.loading);

    const snippets = useSelector((state) => state.snippets.snippets);

    const codeSnippet = useSelector((state) => state.snippets.codeSnippet);

    const user = useSelector((state) => state.user.instance);

    const [editName,setEditName]=useState(false)

    const [showSideBar,setSideBar]=useState(false)

    const [newCodeSnippets,setNewCodeSnippets]=useState([{}])

    const [result, setResult] = useState(defaultResult);

    const [terminalFlag, setTerminalFlag] = useState(false);

    const [loginFlag, setLoginFlag] = React.useState(false);

    const [snippetIndex, setSnippetIndex] = React.useState(-1);

    const [tabIndex, setTabIndex] = React.useState(0);


    const navigate = useNavigate();

const handleLangChange = (value)  =>{
    var temp = {...result}
    temp['mode'] = value

    let language = langMap.languages.filter((lang)=>{
        return lang.id==value
    })

    temp['editorMode']=language[0].mode

    if(language[0].code){
        temp['code']=language[0].code
    }else{
        temp['code']='//write your code here'
    }

    temp['fileName'] = 'Main'+language[0].ext

    console.log("coding editor",temp)

    setResult({...temp})

    dispatch(clearSnippet())

    setSideBar(false)

}

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

function isToday(date){
    // Create date from input value
    var inputDate = new Date(date);

    // Get today's date
    var todaysDate = new Date();

    // call setHours to take the time out of the comparison
    if(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
        // Date equals today's date
        return true;
    }
    return false;
}

function formatDate(date) {
    var d = new Date(date)
    if(isToday(date)){
        return 'Today'
    }else{
        var month = ''+monthNames[d.getMonth()]
        var day = '' + d.getDate()
        var year = d.getFullYear();

        if (day.length < 2) 
            day = '0' + day;

        return day+" "+month
    }
}

function formatTime(date) {
    var d = new Date(date)
    return d.toLocaleTimeString();
}

const save = (name) => {
    if(user!=null&&user._id!=null){
        
        if(codeSnippet!=null&&codeSnippet._id!=null){
            //update code
            console.log("Snippet Update");
            dispatch(updateSnippet(result.mode,codeSnippet.programName,result.code,user._id,"",codeSnippet._id))
        }else{
            //save code
            if(typeof name != 'string'){
                //show name change dialog and then save
                setEditName(true)
                return;
            }

            let languages = langMap.languages.filter((lang)=>{
                return lang.id == result.mode
            })
            console.log("Snippet Save",languages);

            let lang;
            if(languages.length>0){
                lang = languages[0]
                dispatch(saveSnippet(result.mode,name+lang.ext,result.code,user._id,""))
            }else{
                dispatch(saveSnippet(result.mode,name,result.code,user._id,""))

            }

            // setTimeout(() => {   // ***
            //     dispatch(fetchSnippets())
            // }, 1000);

        }
    }else{
        // show the login error dialog
        dispatch(showError("Error","Please login to save code"))
    }
}

const reset = () => {
    console.log("new snippet");
    dispatch(clearSnippet())
}

const deleteSnip = (event,snippet) => {
    event.stopPropagation()
    console.log("delete snippet");
    dispatch(deleteSnippet(snippet._id))
}



const getSnippetsList = () =>{
    return <>
        <Box sx={{mt:2,mb:2}}>
                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.4rem',textDecoration: 'underline',color:'pink.hot'}}>Snippets List</Typography>
        </Box>
        {
            newCodeSnippets.map((snippet,index)=>{
                return <Box sx={{my:1,border:1,borderRadius:'5px',cursor:'pointer',borderColor:'secondary.main'}} onClick={()=>{openSnippet(snippet,index)}}>
                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <Typography sx={{my:1,mx:1,fontSize:'1.2rem',fontWeight:400,overflow:'hidden'}}>{snippet.programName}</Typography>
                        <IconButton aria-label="New" size="small" color="error" onClick={(event)=>{deleteSnip(event,snippet)}}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <Typography style={{fontSize:'0.9rem',color:'green',textAlign:'center'}} sx={{mb:1,mx:1}}>{snippet.lang}</Typography>
                    <Typography sx={{mb:1,mx:1,fontSize:'0.9rem',}}>{snippet.date}</Typography>

                    </Box>
                </Box>
            })
        }
    </>
}

const openSnippet = (snippet,index)=>{
    console.log("Snippet code",snippet)
    dispatch(setCodeSnippet(snippet))
    setSideBar(false)
    setSnippetIndex(index)
}


const handleFormSubmit = () => {
    setTabIndex(1);
    dispatch(runCodeEditor(result.mode,result.code,user._id,result.stdinput))
}

function onChange(newValue) {
    console.log("change", newValue);
    setResult({...result,'code':newValue})
}

function handleOnClose() {
    console.log("sidebar closed");
    setSideBar(!showSideBar)
}

useEffect(() => {
console.log("languages",langMap)
if(user!=null && user._id!=null){
    console.log("fetch snippet list")
    dispatch(fetchSnippets())
    setLoginFlag(false)
    setSideBar(false)
}
},[user])

useEffect(() => {
    if(codeSnippet!=null&&codeSnippet.programCode!=null){
        let language = langMap.languages.filter((lang)=>{
            return lang.id==codeSnippet.language_code
        })
        setResult({...result,mode:codeSnippet.language_code,code:codeSnippet.programCode,editorMode:language[0].mode})
        
    }
},[codeSnippet])

useEffect(() => {
    console.log("snippets ",snippets)

    if(snippets!=null && snippets.length>0){
        let newSnippets = snippets.map((snippet)=>{

            let languages = langMap.languages.filter((lang)=>{
                return lang.id == snippet.language_code
            })

             snippet['lang'] = languages[0].short

             snippet['date'] = formatDate(Date.parse(snippet.lastUpdateDate))

             return snippet
            
        })

        setNewCodeSnippets(newSnippets)
        
    }else{
        setNewCodeSnippets([])
    }
},[snippets])

useEffect(() => {
    console.log("fileId ",fileId)
    if(fileId!=null&&fileId.length>0){
        setTerminalFlag(true)
    }
},[fileId])


const confirmSave = useCallback((name) => {
    setEditName(false)
    save(name)
})

const closeDialog = useCallback(() => {
    setEditName(false)
})

const handleLogin = (event) => {
    setSideBar(false);
    setLoginFlag(!loginFlag);
  };

  const hover = {
    "&:hover": {
      opacity: "1",
    },
  };
  return (<div style={{margin:0}}>

        <SideMenu isOpen={showSideBar} onClose={ handleOnClose }>
                {
                    langMap.languages.map((language)=>{
                        return  <Box sx={{p:0.5}}><Box sx={{display:'flex',flexDirection:'row',alignItems:'center',cursor:'pointer',overflow:'hidden',backgroundColor:language.id==result.mode?grey[400]:''}} onClick={(e)=>{handleLangChange(language.id)}}>
                            <img src={language.icon} style={{width:'30px', height:'30px',padding:'2px',background:'white'}}></img>
                            <Typography sx={{px:2,fontSize:'1rem',color:'white'}}>{language.name} Compiler</Typography>
                        </Box></Box>
                    })
                }
        </SideMenu>

        <Box sx= {{display:'flex', flexDirection:'row',my:2}}>
            <Box sx={{display:'flex', flexDirection:'row',cursor:'pointer'}} onClick={(e)=>{navigate('/')}}>
                <img src={process.env.PUBLIC_URL +'/new-logo.png'} width="40px" height="30px" style={{margin:'auto 10px'}}/>
                <Typography sx={{color:'#d84315',my:'auto',fontSize:'1.2em'}}>ALT Code</Typography>
            </Box>
            {/* <Box sx={{mx:2,my:'auto'}} >
                <Link to={'/coding/practice'} style={{textDecoration:'none'}}><Button variant="contained" color="primary" sx={{mx:1,color:'white',my:'auto'}} endIcon={<ArrowOutwardIcon/>}>Practise Programs</Button></Link>
            </Box> */}
        </Box>
    <TabsUnstyled defaultValue={false} value={tabIndex} onChange={(value)=>{setTabIndex(value)}}>
        <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
        <IconButton size="medium" sx={{color:blue[600],my:'auto'}} onClick={(event)=>{setSideBar(!showSideBar)}}><MenuIcon></MenuIcon></IconButton>
        <TabsList>
            <Tab onClick={(value)=>{setTabIndex(0)}}>{result.fileName}</Tab>
            <Tab onClick={(value)=>{setTabIndex(1)}}> Output</Tab>
        </TabsList>

        <IconButton  onClick={handleFormSubmit}><PlayArrow size="large" sx={{color:'white',backgroundColor:blue[600],my:'auto'}}></PlayArrow></IconButton>

        </Box>
        <CodeBox>
        <TabPanel value={0}><Fragment>
            <AceEditor
            mode={result.editorMode}
            theme="monokai"
            onChange={onChange}
            fontSize={18}
            name="code"
            width='100%'
            style={{position: "absolute",top: "0px",height: "100%",bottom: "0px"}}
            showPrintMargin={true}
            showGutter={true}
            highlightActiveLine={true}
            editorProps={{ $blockScrolling: true }}
            value={result.code}
            setOptions={{
            enableBasicAutocompletion: false,
            enableLiveAutocompletion: false,
            enableSnippets: true
            }}
            />
            </Fragment>
        </TabPanel>
        <TabPanel value={1}><Fragment>
            <div style={{width:'100%'}}>
                {
                    terminalFlag?<TerminalComponent fileId={fileId} langMode={result.mode} sx={{width:'100%',height:'auto',border:1,borderColor:'primary.main'}}/>:<></>
                }
            </div>
            </Fragment>
        </TabPanel>
        </CodeBox>
    </TabsUnstyled>
  </div>
  )

}

export default MobileEditor;