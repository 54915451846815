export const BRANCH_LOAD= 'BRANCH_LOAD';
export const LOAD_ACADEMIC_BATCH_BRANCH = 'LOAD_ACADEMIC_BATCH_BRANCH';
export const UPLOAD_STUDENT= 'UPLOAD_STUDENT';
export const STUDENT_LOAD= 'STUDENT_LOAD';
export const BATCH_RESULTS_LOAD= 'BATCH_RESULTS_LOAD';
export const BATCH_RESULTS_SUCCESS= 'BATCH_RESULTS_SUCCESS';
export const BATCH_RESULTS_FAILURE= 'BATCH_RESULTS_FAILURE';
export const CANCEL_ERROR= 'CANCEL_ERROR';
export const SHOW_ERROR= 'SHOW_ERROR';
export const CONSOLIDATE_RESULTS_LOAD= 'CONSOLIDATE_RESULTS_LOAD';
export const CONSOLIDATE_RESULTS_SUCCESS= 'CONSOLIDATE_RESULTS_SUCCESS';
export const CONSOLIDATE_RESULTS_FAILURE= 'CONSOLIDATE_RESULTS_FAILURE';
export const CODE_RUNNER_START= 'CODE_RUNNER_START';
export const CODE_RUNNER_SUCCESS= 'CODE_RUNNER_SUCCESS';
export const CODE_RUNNER_FAILURE= 'CODE_RUNNER_FAILURE';
export const LOGIN_USER= 'LOGIN_USER';
export const LOGOUT= 'LOGOUT';
export const PROFILE= 'PROFILE';
export const PROFILE_LOAD_SUCCESS= 'PROFILE_LOAD_SUCCESS';
export const PROFILE_LOAD_FAIURE= 'PROFILE_LOAD_FAIURE';
export const LOGIN_USER_SUCCESS= 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_FAILURE= 'LOGIN_USER_FAILURE';
export const REGISTER_USER= 'REGISTER_USER';
export const REGISTER_USER_SUCCESS= 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_FAILURE= 'REGISTER_USER_FAILURE';
export const UPLOAD_PROGRAMS= 'UPLOAD_PROGRAMS';
export const UPLOAD_PROGRAM_SUCCESS= 'UPLOAD_PROGRAM_SUCCESS';
export const UPLOAD_PROGRAM_FAILURE= 'UPLOAD_PROGRAM_FAILURE';
export const LOAD_PRACTISE_PROBLEMS= 'LOAD_PRACTISE_PROBLEMS';
export const LOAD_PRACTISE_PROBLEMS_SUCCESS= 'LOAD_PRACTISE_PROBLEMS_SUCCESS';
export const LOAD_PRACTISE_PROBLEMS_FAILURE= 'LOAD_PRACTISE_PROBLEMS_FAILURE';
export const LOAD_POTD= 'LOAD_POTD';
export const LOAD_POTD_SUCCESS= 'LOAD_POTD_SUCCESS';
export const LOAD_POTD_FAILURE= 'LOAD_POTD_FAILURE';
export const SUBMIT_CODE_START= 'SUBMIT_CODE_START';
export const SUBMIT_CODE_SUCCESS= 'SUBMIT_CODE_SUCCESS';
export const SUBMIT_CODE_FAILURE= 'SUBMIT_CODE_FAILURE';
export const UPDATE_PROGRAM= 'UPDATE_PROGRAM';
export const UPDATE_PROGRAM_SUCCESS= 'UPDATE_PROGRAM_SUCCESS';
export const UPDATE_PROGRAM_FAILURE= 'UPDATE_PROGRAM_FAILURE';
export const DELETE_PROGRAM= 'DELETE_PROGRAM';
export const DELETE_PROGRAM_SUCCESS= 'DELETE_PROGRAM_SUCCESS';
export const DELETE_PROGRAM_FAILURE= 'DELETE_PROGRAM_FAILURE';
export const LOAD_LEADERBOARD= 'LOAD_LEADERBOARD';
export const LOAD_LEADERBOARD_SUCCESS= 'LOAD_LEADERBOARD_SUCCESS';
export const LOAD_LEADERBOARD_FAILURE= 'LOAD_LEADERBOARD_FAILURE';
export const RUN_CODE_EDITOR= 'RUN_CODE_EDITOR';
export const CODE_EDITOR_SUCCESS= 'CODE_EDITOR_SUCCESS';
export const CODE_EDITOR_FAILURE= 'CODE_EDITOR_FAILURE';
export const FORGOT_PASSWORD= 'FORGOT_PASSWORD';
export const FORGOT_PASSWORD_SUCCESS= 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE= 'FORGOT_PASSWORD_FAILURE';
export const RESET_PASSWORD= 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS= 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE= 'RESET_PASSWORD_FAILURE';
export const SAVE_SNIPPET= 'SAVE_SNIPPET';
export const SAVE_SNIPPET_SUCCESS= 'SAVE_SNIPPET_SUCCESS';
export const SAVE_SNIPPET_FAILURE= 'SAVE_SNIPPET_FAILURE';
export const UPDATE_SNIPPET= 'UPDATE_SNIPPET';
export const UPDATE_SNIPPET_SUCCESS= 'UPDATE_SNIPPET_SUCCESS';
export const UPDATE_SNIPPET_FAILURE= 'UPDATE_SNIPPET_FAILURE';
export const FETCH_SNIPPETS= 'FETCH_SNIPPETS';
export const FETCH_SNIPPETS_SUCCESS= 'FETCH_SNIPPETS_SUCCESS';
export const FETCH_SNIPPETS_FAILURE= 'FETCH_SNIPPETS_FAILURE';
export const DELETE_SNIPPET= 'DELETE_SNIPPET';
export const DELETE_SNIPPET_SUCCESS= 'DELETE_SNIPPET_SUCCESS';
export const DELETE_SNIPPET_FAILURE= 'DELETE_SNIPPET_FAILURE';
export const CLEAR_SNIPPET= 'CLEAR_SNIPPET';
export const SET_CODE_SNIPPET= 'SET_CODE_SNIPPET';
export const LOAD_PROGRAM_DETAIL= 'LOAD_PROGRAM_DETAIL';
export const LOAD_PROGRAM_DETAIL_SUCCESS= 'LOAD_PROGRAM_DETAIL_SUCCESS';
export const LOAD_PROGRAM_DETAIL_FAILURE= 'LOAD_PROGRAM_DETAIL_FAILURE';





