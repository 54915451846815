import React, { useState, useEffect ,Fragment} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, styled, useTheme } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import {
    Tabs,
    Tab,
    CircularProgress,
    Typography,
} from '@mui/material'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { loginUser,registerUser } from '../actions';
import { Link,useNavigate } from "react-router-dom";

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2)
  }))

const UserTab = styled(Tab)(() => ({
    fontWeight: 400,
    fontSize: 14,
    margin:0.75,
    width:'50%',
}))

const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
}))

const ContentBox = styled(Box)(() => ({
    height: '100%',
    padding: '32px',
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.01)',
}))

const StyledProgress = styled(CircularProgress)(() => ({
    position: 'absolute',
    top: '6px',
    left: '25px',
}))

export const LoginDialog = (show)=>{

    const dispatch = useDispatch();

    var [activeTabId, setActiveTabId] = useState(0);

    const [loading, setLoading] = useState(false)

    const [close, setClose] = useState(true)

    const navigate = useNavigate();


    const [userInfo, setUserInfo] = useState({
        email: '',
        password: '',
        confirmPassword:'',
        collegeName:'',
        name:'',
    })



    const handleChange = ({ target: { name, value } }) => {
        let temp = { ...userInfo }
        temp[name] = value
        setUserInfo(temp)
    }

    const handleClose = () => {
        console.log("clicked outside")
        if(show){
            setClose(false)
        }
    }

    const handleFormSubmit = async (event) => {
       
        try {
           setLoading(true)
           if(activeTabId==0){
                dispatch(loginUser(userInfo.email,userInfo.password))
           }else{
                dispatch(registerUser(userInfo.email,userInfo.password,userInfo.collegeName,userInfo.name))
           }
        } catch (e) {
            console.log(e)
            setLoading(false)
        }
    }

    const addValRule= ()=>{
        console.log("added validation rule")
    
        ValidatorForm.removeValidationRule('isPasswordMatch');
        ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
            if (value !== userInfo.password) {
                return false;
            }
            return true;
        });
    }
    
    
    // useEffect(() => {
    
    
    //  // Anything in here is fired on component mount.
    //  return () => {
    //     // Anything in here is fired on component unmount.
    //     // remove rule when it is not needed
    //     ValidatorForm.removeValidationRule('isPasswordMatch');
    
    // }
    // },[])

    const forgotPassword = async ()=>{
        console.log("forgot password")
        setClose(false)
        navigate('/user/forgotpassword')
    }

    return <Dialog
                open={show&&close}
                sx={{px:3}}
                fullWidth
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
             <ContentBox>

            <JustifyBox>
                <Typography sx={{ fontWeight: 500,fontSize:'h5.fontSize' }}>Please Login to Continue</Typography>
            </JustifyBox>

            <Tabs
                value={activeTabId}
                onChange={(e, id) => setActiveTabId(id)}
                indicatorColor="primary"
                textColor="primary"
                sx={{m:2,width:'100%'}}
                centered
            >
                <UserTab sx={{ fontWeight:  500,fontSize:'h6.fontSize' }} label="Sign In"/>
                <UserTab sx={{ fontWeight:  500,fontSize:'h6.fontSize' }} label="Sign Up"/>
            </Tabs>
            {activeTabId === 0 && (<Fragment>
               
                    <ValidatorForm sx={{mt:2}} onSubmit={handleFormSubmit}>
                                    <TextValidator
                                        sx={{ mb: 3, width: '100%' }}
                                        variant="outlined"
                                        size="small"
                                        label="Email"
                                        onChange={handleChange}
                                        type="email"
                                        name="email"
                                        value={userInfo.email}
                                        validators={['required', 'isEmail']}
                                        errorMessages={[
                                            'this field is required',
                                            'email is not valid',
                                        ]}
                                    />
                                    <TextValidator
                                        sx={{ mb: '12px', width: '100%' }}
                                        label="Password"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        name="password"
                                        type="password"
                                        value={userInfo.password}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />

                                    <JustifyBox m={2}>
                                        <Box position="relative">
                                            <Button
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading>0}
                                                type="submit"
                                            >
                                                Sign in
                                            </Button>
                                            {loading && (
                                                <StyledProgress
                                                    size={24}
                                                    className="buttonProgress"
                                                />
                                            )}
                                        </Box>

                                    </JustifyBox>

                                    <Typography onClick={forgotPassword} sx={{ fontWeight: 400,fontSize:'1.05rem',textAlign:'right',color:'primary',cursor:'pointer' }}>Forgot password?</Typography>

                    </ValidatorForm>
            </Fragment>)}
            {activeTabId === 1 && (<Fragment>
                <ValidatorForm sx={{mt:2}} onSubmit={handleFormSubmit}>
                                    <TextValidator
                                        sx={{ mb: 3, width: '100%' }}
                                        variant="outlined"
                                        size="small"
                                        label="Email"
                                        onChange={handleChange}
                                        type="email"
                                        name="email"
                                        value={userInfo.email}
                                        validators={['required', 'isEmail']}
                                        errorMessages={[
                                            'this field is required',
                                            'email is not valid',
                                        ]}
                                    />
                                    <TextValidator
                                        sx={{ mb: '12px', width: '100%' }}
                                        label="Password"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        name="password"
                                        type="password"
                                        value={userInfo.password}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />

                                    <TextValidator
                                        sx={{ mb: '12px', width: '100%' }}
                                        label="Confirm Password"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        name="confirmPassword"
                                        type="password"
                                        onFocus={addValRule}
                                        value={userInfo.confirmPassword}
                                        validators={['required','isPasswordMatch']}
                                        errorMessages={['this field is required','Password mismatch']}
                                    />

                                    <TextValidator
                                        sx={{ mb: '12px', width: '100%' }}
                                        label="Name"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        name="name"
                                        type="text"
                                        value={userInfo.name}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />

                                    <TextValidator
                                        sx={{ mb: '12px', width: '100%' }}
                                        label="Institution/Organisation Name"
                                        variant="outlined"
                                        size="small"
                                        onChange={handleChange}
                                        name="collegeName"
                                        type="text"
                                        value={userInfo.collegeName}
                                        validators={['required']}
                                        errorMessages={['this field is required']}
                                    />

                                    <JustifyBox m={2}>
                                        <Box position="relative">
                                            <Button
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading>0}
                                                type="submit"
                                            >
                                                Sign Up
                                            </Button>
                                            {loading && (
                                                <StyledProgress
                                                    size={24}
                                                    className="buttonProgress"
                                                />
                                            )}
                                        </Box>

                                    </JustifyBox>
                    </ValidatorForm>
                </Fragment>)}
            </ContentBox>

        </Dialog>
}