import { CODE_RUNNER_FAILURE, CODE_RUNNER_START, CODE_RUNNER_SUCCESS,SUBMIT_CODE_START,SUBMIT_CODE_SUCCESS,SUBMIT_CODE_FAILURE, RUN_CODE_EDITOR, CODE_EDITOR_SUCCESS, CODE_EDITOR_FAILURE } from "../../constants/actionTypes";

const initialState = {
    compileRun : false,
    submitCode:false,
    result:'',
    fileId:''
};
    
function codeRunnerReducer(state = initialState, action) {
    switch (action.type) {
        case CODE_RUNNER_START:  return { ...state, compileRun: true ,result:''}
        case CODE_RUNNER_SUCCESS: {
            console.log("CODE_RUNNER_SUCCESS action",action.payload)
            return { ...state, result:action.payload, compileRun: false}
        }
        case CODE_RUNNER_FAILURE: return { ...state,compileRun: false,result:''}
        case RUN_CODE_EDITOR:  return { ...state, compileRun: true ,fileId:''}
        case CODE_EDITOR_SUCCESS: {
            console.log("CODE_RUNNER_SUCCESS action",action.payload)
            return { ...state, fileId:action.payload, compileRun: false}
        }
        case CODE_EDITOR_FAILURE: return { ...state,compileRun: false,fileId:''}
        case SUBMIT_CODE_START:  return { ...state, submitCode: true,result:'' }
        case SUBMIT_CODE_SUCCESS: {
            console.log("SUBMIT_CODE_START action",action.payload)
            return { ...state, result:action.payload, submitCode: false}
        }
        case SUBMIT_CODE_FAILURE: return { ...state,submitCode: false,result:''}
        default:return state;
    }
};
        
export default codeRunnerReducer;