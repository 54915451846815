import { Typography } from  '@mui/material'
import SimpleCard from '../component/SimpleCard'
import Paper from '@mui/material/Paper';
import { Box,styled } from '@mui/system'

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: {
      margin: '16px',
  },
}))

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center',
}))

function Faq() {
  return <Container>
          <div sx={{mt:3,}}>
              <h2 style={{marginTop:'10px',textAlign:'center'}}>Frequently Asked Questions</h2>            
          </div>
        <SimpleCard className="card" sx= {{my:3}}>
          <Typography sx={{fontWeight:'bold',color:'red',fontSize:'1.5rem'}}>1. How to find result url?</Typography>
          <Typography sx={{my:2}}>Step1: Go to JntuaResults.ac.in website and click on result link</Typography>
          <Paper>
            <JustifyBox>
              <img src="Click.jpeg" style={{width:'75%',height:'75%'}}/>
            </JustifyBox>
          </Paper>
          <Typography  sx={{my:2}}>Step2: Copy the link from browser addressbar</Typography>
          <Paper>
            <JustifyBox>
              <img src="Copy.jpeg" style={{width:'75%',height:'75%'}}/>
            </JustifyBox>
          </Paper>
          <Typography  sx={{my:2}}>Step3: Paste the link into result url textfield</Typography>
          <Paper>
            <JustifyBox>
              <img src="Paste.jpeg" style={{width:'75%',height:'75%'}}/>
            </JustifyBox>
          </Paper>

          <Typography sx={{fontWeight:'bold',mt:3,mb:2,color:'red',fontSize:'1.5rem'}}>2. Does this works only for JNTUA and JNTUK results?</Typography>
          <Typography sx={{my:2}}>yes.Currently it works only for <span style={{fontWeight:'bold'}}>JNTUA and JNTUK</span> results</Typography>
        </SimpleCard>
    </Container>
}
export default Faq;
