import batchResultReducer from "./batchResultsReducer";
import errorDialogReducer from "./errorDialogReducer";
import consolidateReducer from "./consolidateReducer";
import codeRunnerReducer from "./codeRunnerReducer";
import uploadProgramReducer from "./uploadProgramReducer";
import publishedChallengesReducer from "./publishedChallengesReducer";
import {combineReducers} from 'redux';
import userReducer from "./userReducer";
import practiceProgramsReducer from "./practiceProgramsReducer";
import leaderBoardReducer from "./leaderBoardReducer";
import codeSnippetsReducer from "./codeSnippetsReducer";
import programReducer from "./programReducer";

const allReducers = combineReducers({
    batchResult:batchResultReducer,
    error:errorDialogReducer,
    consolidate:consolidateReducer,
    codeRunner:codeRunnerReducer,
    uploadProgram:uploadProgramReducer,
    user:userReducer,
    potd:publishedChallengesReducer,
    practice:practiceProgramsReducer,
    leaderboard:leaderBoardReducer,
    snippets:codeSnippetsReducer,
    program:programReducer
});
export default allReducers