import React, { useState, useEffect ,Fragment} from 'react'
import Button from '@mui/material/Button';
import { Box, styled, useTheme } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import {
    CircularProgress,
    Typography,
} from '@mui/material'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import SimpleCard from '../component/SimpleCard'
import { resetPassword } from '../actions';
import { useSearchParams } from "react-router-dom";


const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
  }))
  
  const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
  }))
  
  const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
  }))
  
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(5)
  }))

  const StyledProgress = styled(CircularProgress)(() => ({
    position: 'absolute',
    top: '6px',
    left: '25px',
}))


function ResetPassword(){

  const [userInfo,setUserInfo] = useState({
                                password:'',
                                repassword:''
                                })

  const [loading, setLoading] = useState(false)

  const resetPasswordFlag = useSelector((state) => state.user.resetPassword);

  const resetPasswordMsg = useSelector((state) => state.user.resetPasswordMsg);

  const dispatch = useDispatch();

  const [message, setMessage] = useState()

  const [searchParams, setSearchParams] = useSearchParams();


  const handleChange = ({ target: { name, value } }) => {
        let temp = { ...userInfo }
        temp[name] = value
        console.log("name",name,"value",value)
        setUserInfo(temp)
        console.log(userInfo)
  }

const handleFormSubmit = async (event) => {
    try {
        let token = searchParams.get("token");
        setLoading(true)
        dispatch(resetPassword(userInfo.password,userInfo.repassword,token))
    } catch (e) {
        console.log(e)
        setLoading(false)
    }
}

useEffect(() => {
  console.log("resetPassword",resetPasswordMsg)
  if(resetPasswordFlag){
      console.log("code changed")
      setLoading(false)
      setMessage(resetPasswordMsg)
  }
},[resetPasswordFlag])

const addValRule= ()=>{
    console.log("added validation rule")

    ValidatorForm.addValidationRule('isPasswordMatch', (value) => {
        if (value !== userInfo.password) {
            return false;
        }
        return true;
    });
}

const removeValRule = () =>{
    console.log("remove validation rule")

    // ValidatorForm.removeValidationRule('isPasswordMatch');

}

useEffect(() => {


 // Anything in here is fired on component mount.
 return () => {
    // Anything in here is fired on component unmount.
    // remove rule when it is not needed
    ValidatorForm.removeValidationRule('isPasswordMatch');

}
},[])



    return (
        <>  
        {
              resetPasswordFlag?<Box sx={{mt:3,mb:3}}>
              <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'2rem',color:'red'}}>{resetPasswordMsg}</Typography>
              <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.5rem',color:'red'}}>Goto Home</Typography>

              </Box>:<>
               <Container sx= {{width:'50%',mx:'auto'}}>
              <SimpleCard className="card">
                  <Box sx={{mt:3,mb:3}}>
                      <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.8rem'}}>Reset Password</Typography>
                  </Box>
  
                  <ValidatorForm sx={{mt:3}} onSubmit={handleFormSubmit}>
  
                                      <TextValidator
                                          sx={{ mb: 2, width: '100%' }}
                                          variant="outlined"
                                          size="large"
                                          label="Enter Password"
                                          onChange={handleChange}
                                          type="password"
                                          name="password"
                                          value={userInfo.password}
                                          validators={['required']}
                                          errorMessages={[
                                              'this field is required'
                                          ]}
                                      />
  
                                      <TextValidator
                                          sx={{ mb: 2, width: '100%' }}
                                          variant="outlined"
                                          size="large"
                                          label="Confirm Password"
                                          onChange={handleChange}
                                          onFocus={addValRule}
                                          onBlur={removeValRule}
                                          type="password"
                                          name="repassword"
                                          value={userInfo.repassword}
                                          validators={['isPasswordMatch','required']}
                                          errorMessages={[
                                              'password mismatch',
                                              'this field is required',
                                          ]}
                                      />
  
                                      <JustifyBox m={2}>
                                          <Box position="relative">
                                              <Button
                                                  size="large"
                                                  variant="contained"
                                                  color="primary"
                                                  disabled={loading>0}
                                                  type="submit"
                                              >
                                                  Submit
                                              </Button>
                                              {loading && (
                                                  <StyledProgress
                                                      size={24}
                                                      className="buttonProgress"
                                                  />
                                              )}
                                          </Box>
  
                                      </JustifyBox>
                                  </ValidatorForm>
              </SimpleCard></Container></>
            }
            
        </>  
);
}

export default ResetPassword;