import { Outlet, Link } from "react-router-dom";
import Navbar from "./Navbar";
import {
    Grid
  } from '@mui/material'

import GoogleAds from "../ads/AdComponent";
import SkyScraperAd from "../ads/SkyScraperAd";
import React, { useState, useEffect ,Fragment} from 'react'
import LargeBannerAd from "../ads/LargeBannerAd";
import { useSelector, useDispatch } from 'react-redux'
import { ErrorDialog } from "./ErrorDialog";

const Layout = () => {

  const error = useSelector((state) => state.error.error);


  useEffect(() => {
    <script>
     (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
  })



  return (
    <>
        <Navbar/>
    <LargeBannerAd/>      
    <Grid container>
        <Grid item lg={1} md={1} sm={1} xs={0} sx={{px:3}}></Grid>
        <Grid item lg={10} md={10} sm={10} xs={12} sx={{px:3,  minHeight: '100% !important',}}>
            <Outlet />
        </Grid>
        <Grid item lg={1} md={1} sm={1} xs={0} sx={{px:3}}>
            {/* <div className="ad-class">
              {/* add your slot id  
              <SkyScraperAd/>
            </div> */}
           
        </Grid>
    </Grid>
      {
        error ? <ErrorDialog show={error}/>:<></>
      }
    </>
  )
};

export default Layout;