import React, { useState, useEffect ,Fragment} from 'react'
import Button from '@mui/material/Button';
import { Box, styled, useTheme } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import {
    CircularProgress,
    Typography,
} from '@mui/material'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import SimpleCard from '../component/SimpleCard'
import { forgotPassword } from '../actions';

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
  }))
  
  const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
  }))
  
  const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
  }))
  
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(5)
  }))

  const StyledProgress = styled(CircularProgress)(() => ({
    position: 'absolute',
    top: '6px',
    left: '25px',
}))


function ForgotPassword(){

  const [email,setEmail] = useState()

  const [loading, setLoading] = useState(false)

  const forgotpassword = useSelector((state) => state.user.forgotPassword);

  const forgotPasswordMsg = useSelector((state) => state.user.forgotPasswordMsg);

  const dispatch = useDispatch();

  const [message, setMessage] = useState()

  const handleChange = ({ target: { name, value } }) => {
    setEmail(value)
  }

const handleFormSubmit = async (event) => {
    try {
       setLoading(true)
       dispatch(forgotPassword(email))
    } catch (e) {
        console.log(e)
        setLoading(false)
    }
}

useEffect(() => {
  console.log("forgotpassword",forgotPasswordMsg)
  if(forgotpassword){
      console.log("code changed")
      setLoading(false)
      setMessage(forgotPasswordMsg)
  }
},[forgotpassword])




    return (
        <Container sx= {{width:'50%',mx:'auto'}}>
            <SimpleCard className="card">
                <Box sx={{mt:3,mb:3}}>
                    <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.8rem'}}>Forgot Password</Typography>
                </Box>

                <ValidatorForm sx={{mt:3}} onSubmit={handleFormSubmit}>


                                    <TextValidator
                                        sx={{ mb: 2, width: '100%' }}
                                        variant="outlined"
                                        size="large"
                                        label="Email"
                                        onChange={handleChange}
                                        type="email"
                                        name="email"
                                        value={email}
                                        validators={['required', 'isEmail']}
                                        errorMessages={[
                                            'this field is required',
                                            'email is not valid',
                                        ]}
                                    />

                                    <JustifyBox m={2}>


                                        <Box position="relative">
                                            <Button
                                                size="large"
                                                variant="contained"
                                                color="primary"
                                                disabled={loading>0}
                                                type="submit"
                                            >
                                                Submit
                                            </Button>
                                            {loading && (
                                                <StyledProgress
                                                    size={24}
                                                    className="buttonProgress"
                                                />
                                            )}
                                        </Box>

                                    </JustifyBox>
                                </ValidatorForm>
            </SimpleCard>

            {
              forgotpassword?<Box sx={{mt:3,mb:3}}>
              <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'2rem',color:'red'}}>{forgotPasswordMsg}</Typography>
              </Box>:<></>
            }
            
        </Container>);
}

export default ForgotPassword;