import React, { useState } from "react";
import {  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography,
  ListItemText, } from '@mui/material'

import { Link } from "react-router-dom";
import { Box,styled } from '@mui/system'

import MenuIcon from "@mui/icons-material/Menu";

const StyledLink = styled(Link)(() => ({
  textDecoration:"none",
  color: "blue",
  fontSize: "20px",
}))

const StyledText = styled(Typography)(({ theme }) => ({
  textDecoration: "none",
  color: "white",
  fontSize: "20px",
  fontWeight:500,
  cursor: 'pointer',
  marginLeft: theme.spacing(10),
  "&:hover": {
    color: "yellow",
  },
}))

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  color: "white",
}))

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);
  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
      >
        <List>
        <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <StyledLink to="/" >Home</StyledLink>
            </ListItemText>
          </ListItem>
          <Divider/>
           <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <StyledLink to="/coding/daily">Program of the Day</StyledLink>
            </ListItemText>
          </ListItem>
          <Divider/> 
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <StyledLink to="/coding/leaderboard">Leader Board</StyledLink>
            </ListItemText>
          </ListItem>
          <Divider/> 
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <StyledLink to="/coding/practice">Practice Programs</StyledLink>
            </ListItemText>
          </ListItem>
          <Divider/> 
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <StyledLink to="/editor">Code Editor</StyledLink>
            </ListItemText>
          </ListItem>
          <Divider/> 
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <StyledText>Login</StyledText>
            </ListItemText>
          </ListItem>          
          <Divider/>
        </List>
      </Drawer>
      <StyledIconButton onClick={() => setOpenDrawer(!openDrawer)}>
        <MenuIcon />
      </StyledIconButton>
    </>
  );
}
export default DrawerComponent;
