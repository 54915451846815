import { LOGIN_USER,LOGIN_USER_SUCCESS,LOGIN_USER_FAILURE, LOGOUT, PROFILE, PROFILE_LOAD_SUCCESS, PROFILE_LOAD_FAIURE, FORGOT_PASSWORD, FORGOT_PASSWORD_SUCCESS, FORGOT_PASSWORD_FAILURE, RESET_PASSWORD, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE} from "../../constants/actionTypes";

const initialState = {
    loading : false,
    instance:{},
    profile:{},
    forgotPassword:false,
    forgotPasswordMsg:'',
    resetPassword:false,
    resetPasswordMsg:''
};
    
function userReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN_USER:  return { ...state, loading: true }
        case LOGIN_USER_SUCCESS: {
            console.log("LOGIN_USER_SUCCESS action",action.payload)
            return { ...state, instance:action.payload.result, loading: false}
        }
        case LOGIN_USER_FAILURE: return { ...state,loading: false}
        case PROFILE:  return { ...state, loading: true }
        case PROFILE_LOAD_SUCCESS: {
            console.log("LOGIN_USER_SUCCESS action",action.payload)
            return { ...state, profile:action.payload, loading: false}
        }
        case PROFILE_LOAD_FAIURE: return { ...state,loading: false}
        case LOGOUT:  return { ...state, instance: null }
        case FORGOT_PASSWORD:  return { ...state, loading: true }
        case FORGOT_PASSWORD_SUCCESS: {
            console.log("FORGOT_PASSWORD action",action.payload)
            return { ...state, forgotPassword:true,forgotPasswordMsg:action.payload, loading: false}
        }
        case FORGOT_PASSWORD_FAILURE: return { ...state,forgotPassword:true,forgotPasswordMsg:action.payload,loading: false}
        case RESET_PASSWORD:  return { ...state, loading: true }
        case RESET_PASSWORD_SUCCESS: {
            console.log("FORGOT_PASSWORD action",action.payload)
            return { ...state, resetPassword:true,resetPasswordMsg:action.payload, loading: false}
        }
        case RESET_PASSWORD_FAILURE: return { ...state,resetPassword:true,resetPasswordMsg:action.payload,loading: false}
        default:return state;
    }
};
        
export default userReducer;