import SimpleCard from '../component/SimpleCard'
import {
    Button,
    Backdrop,
    CircularProgress,
    Tabs,
    Tab,
    Typography,
    Table,
    TableHead,
    TableCell,
    TableContainer,
    TableBody,
    Tooltip, 
    TableRow,
    Grid
} from '@mui/material'
import { Box,styled } from '@mui/system'
import React, { useState, useEffect ,Fragment,useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { showError,closeError, deleteProgram, fetchPracticePrograms, loadLeaderBoard } from '../actions'
import { ErrorDialog } from '../component/ErrorDialog'
import { useNavigate  } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from '../component/ConfimationDialog'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
}))
  
const FlexBox = styled(Box)(() => ({
display: 'flex',
alignItems: 'center',
}))

const UserTab = styled(Tab)(() => ({
    fontWeight: 400,
    fontSize: 14,
    margin:0.75,
    width:'50%',
}))

const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
}))

const ContentBox = styled(Box)(() => ({
    height: '100%',
    padding: '32px',
    position: 'relative',
    background: 'rgba(0, 0, 0, 0.01)',
}))

const StyledProgress = styled(CircularProgress)(() => ({
    position: 'absolute',
    top: '6px',
    left: '25px',
}))

const StyledTable = styled(Table)(({ theme }) => ({
    whiteSpace: 'pre',
    overflowX:'auto',
    '& thead': {
        '& tr': {
            '& th': {
                paddingLeft: 0,
                paddingRight: 0,
            },
        },
    },
    '& tbody': {
        '& tr': {
            '& td': {
                paddingLeft: 0,
                textTransform: 'capitalize',
            },
        },
    },
  }))

const CodersLeaderBoard = ()=>{

    const dispatch = useDispatch();

    var [activeTabId, setActiveTabId] = useState(0);

    const loading = useSelector((state) => state.leaderboard.loading);
  
    const weeks = useSelector((state) => state.leaderboard.weeks) ;

    const user = useSelector((state) => state.user.instance);

    var [codersList, setCodersList] = useState([]);

    useEffect(() => {
        dispatch(loadLeaderBoard())
    },[])

    useEffect(() => {
        var keys = Object.keys(weeks)
        let key = keys[activeTabId]
        setCodersList(weeks[key])
        console.log("Coders List",weeks[key])
    },[activeTabId])


    return(
     <Container>
             <Box sx={{mt:3,mb:3}}>
                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.8rem'}}>Leaderboard</Typography>
            </Box>

            <SimpleCard>
                <Grid container>
                    <Grid item lg={2} md={2} sm={2} xs={12}>
                    <Tabs
                    value={activeTabId}
                    onChange={(e, id) => setActiveTabId(id)}
                    indicatorColor="primary"
                    textColor="primary"
                    orientation='vertical'
                    sx={{m:2}}
                >
                {
                    weeks!=null?Object.entries(weeks).map(k => {
                        console.log('weeks index',k[0])
                        return<UserTab sx={{ fontWeight:  500,fontSize:'h6.fontSize',width:'100%',border:1 }} label={k[0]}/>
                    }):<></>
                }
                </Tabs>
                    </Grid>
                    <Grid item lg={10} md={10} sm={10} xs={12}>
                    <TableContainer>
                <StyledTable  sx={{px:3,mx:3}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Total Points</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {
                    codersList!=null&&codersList.length>0?codersList.map((coder)=>{
                        return (<TableRow>
                            <TableCell>{coder.userName}</TableCell>
                            <TableCell>{coder.totalPoints}</TableCell>
                        </TableRow>)
                    }):<></>
                }
                </TableBody>
                </StyledTable>
                </TableContainer>
                </Grid>
                </Grid>
            </SimpleCard>
            <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
            >
                <CircularProgress color="inherit" sx={{mx:2}}/>
                <div>
                <h2>Please wait while loading programs...</h2>
                </div>
            </Backdrop>
</Container>)

}

export default CodersLeaderBoard