import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  useMediaQuery,
  Box,
} from  '@mui/material'
import { LoginDialog } from "./LoginDialog";
import Menu from '@mui/material/Menu';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme,alpha } from '@mui/system'
import { Link,useNavigate } from "react-router-dom";
import DrawerComponent from "./Drawer";
import React, { useState, useEffect ,Fragment} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { logout, profile } from '../actions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';

const NavLinks = styled('div')(({ theme }) => ({
  marginLeft: theme.spacing(5),
  display: "flex",
}))

const StyledLogo = styled(Typography)(() => ({
  flexGrow: "1",
  cursor: "pointer",
  margin:'auto' 
}))

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "white",
  fontSize: "1.1rem",
  fontWeight:400,
  marginLeft: theme.spacing(10),
  "&:hover": {
    color: '#000000'
  },
}))

const StyledText = styled(Typography)(({ theme }) => ({
  textDecoration: "none",
  color: "white",
  fontSize: "1.1rem",
  fontWeight:400,
  cursor: 'pointer',
  marginLeft: theme.spacing(10),
  "&:hover": {
    color: '#000000'
  },
}))

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: "1.1rem",
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

function Navbar() {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [profileEl, setProfileEl] = React.useState(null);
  const [loginFlag, setLoginFlag] = React.useState(false);
  const open = Boolean(anchorEl);
  const profileOpen = Boolean(profileEl);

  const apiLoading = useSelector((state) => state.user.loading);
  
  const error = useSelector((state) => state.error.error);

  const user = useSelector((state) => state.user.instance) ;

  const dispatch = useDispatch(); 

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const openProfile = (event) => {
    setProfileEl(event.currentTarget);
  };

  const handleLogin = (event) => {
    setLoginFlag(!loginFlag);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setProfileEl(null)
  };

  var [userText,setUserText] = React.useState("");

  useEffect(() => {
    if(user!=null){
      console.log("user printed",user.email)
      setUserText(user.email)
      setLoginFlag(false)
    }

    if(error){
      setLoginFlag(false)
    }
  },[user,error])

  const openPage =  (link)=>{
    navigate(link)
    setAnchorEl(null);
  }

  const logoutCall =()=>{
    dispatch(logout())
    setProfileEl(null)
  }

  const profileCall =()=>{
    openPage('/user/profile')
    dispatch(profile())
    setProfileEl(null)
  }

  return (
    <AppBar position="static">
      <CssBaseline />
      <Toolbar>
        <Link to="/" style={{ textDecoration: "none", color: "white", flexGrow: "1",cursor: "pointer",}}>
          <Box sx={{display:'flex',flexDirection:'rows'}}>
          <img src={process.env.PUBLIC_URL +'/new-logo.png'} width="80px" height="50px"></img>
          <StyledLogo variant="h4" >
            ALT Code
          </StyledLogo>
          </Box>
        </Link>
        {isMobile ? (
          <DrawerComponent />
        ) : (
          <NavLinks>
             {/* {
              user!=null&&user.userType!='admin'? 
              <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={()=>{openPage("/")}}
              variant="contained"
              sx={{mx:1}}
              disableElevation
              >
              Home
            </Button>:<></>
            } */}

      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={()=>{openPage("/")}}
        variant="contained"
        sx={{mx:1}}
        disableElevation
      >
      Online Compiler
      </Button> 



            {/* <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                variant="contained"
                sx={{mx:1}}
                disableElevation
                endIcon={<KeyboardArrowDownIcon />}
              >
            Daily Coding
            </Button>

            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >

            <MenuItem onClick={()=>{openPage('/coding/daily')}}>Program of the Day</MenuItem>
            <MenuItem onClick={()=>{openPage('/coding/leaderboard')}}>Leader Board</MenuItem>
              
            </Menu> */}

            {/* <Button
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={()=>{openPage("/coding/practice")}}
              variant="contained"
              sx={{mx:1}}
              disableElevation
            >
            Practice Programs
          </Button>  */}


            {
              user!=null&&user.userType=='admin'? 
              <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={()=>{openPage("/coding/upload")}}
                variant="contained"
                sx={{mx:1}}
                disableElevation
              >
              Upload Programs
            </Button>:<></>
            }

            {
              user!=null&&user.email!=null&&user.email.length>0 ?
              <> 

              <Button
              id="profile-button"
              aria-controls={profileOpen ? 'profile-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={profileOpen ? 'true' : undefined}
              variant="contained"
              disableElevation
              sx={{mx:1}}
              onClick={openProfile}
              endIcon={<KeyboardArrowDownIcon />}
              >
                {user.name}
              </Button>

                <Menu
                id="profile-menu"
                anchorEl={profileEl}
                open={profileOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'profile-button',
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={()=>{profileCall()}}> <Avatar /> Profile</MenuItem>

              <MenuItem onClick={()=>{logoutCall()}}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
                 Logout</MenuItem>
                
              </Menu></>
            :
            <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleLogin}
            variant="contained"
            sx={{mx:1}}
            disableElevation
          >
          Login
          </Button> 
            // <StyledText onClick={handleLogin}>
            //   Login
            // </StyledText>
            }
          </NavLinks>
        )}
      </Toolbar>
      {
        loginFlag?<LoginDialog></LoginDialog>:<></>
      }
    </AppBar>
  );
}
export default Navbar;
