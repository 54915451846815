import React, { useState, useEffect ,Fragment} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, styled, useTheme } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import {
    Tabs,
    Tab,
    CircularProgress,
    Typography,
} from '@mui/material'
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator'
import { Link,useNavigate } from "react-router-dom";

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
  }))

export const CodeRunDialog = ({show,codeInputs,closeCallback,inputValuesCallback})=>{

    const [inputs,setInputs] =  useState({
        values:[]
    });

    const [close, setClose] = useState(true)

    const handleClose = () => {
        console.log("clicked outside")
        if(show){
            // setClose(false)
            closeCallback()
        }
    }

    const handleValueChange = ({ target: { name, value } })  =>{
        var temp = inputs.values
        
        let mIndex =-1
        temp.map((param,index)=>{
            if(param.varName==name){
                mIndex = index
            }
        })
    
        if(mIndex!=-1){
            temp[mIndex].value = value
        }
        console.log('values',temp)
    
        setInputs({...inputs,values:temp})
    
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        console.log("compile & run program")
        inputValuesCallback(inputs.values)
    }

    useEffect(() => {
        let mInputs=[]
        console.log('inputs',codeInputs)
        codeInputs.map((input,index)=>{
            mInputs.push({...input,value:''})
        })
        setInputs({...inputs,values:mInputs})
      },[])

    return <Dialog
            open={show&&close}
            sx={{p:3}}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            
            <ValidatorForm onSubmit={handleFormSubmit}>

            <JustifyBox>
                    <Typography style={{margin:'5px',textAlign:'center',fontWeight:400,fontSize:'1.2rem'}}>Enter Inputs</Typography>
            </JustifyBox>
            <JustifyBox>
                <Box sx={{display:'flex',flexDirection: 'column'}}>
                {inputs.values.map((input,index)=>{
                       return <Box sx={{my:2}}><TextValidator name={input.varName} value={input.value} onChange={handleValueChange} validators={['required']} label={input.varName+" value"} sx={{mx:1}}></TextValidator></Box>
                    })}
                </Box>
            </JustifyBox>
            <JustifyBox>
            <Button style={{ margin:'5px',fontWeight:600,color:'white'}} variant="contained" color="secondary" onClick={()=>handleClose()}>Cancel</Button>

            <Button style={{ margin:'5px',fontWeight:600}} variant="contained" color="primary" type="submit">Execute</Button>
            </JustifyBox>
            </ValidatorForm>

        </Dialog>
}