import { createBrowserHistory } from 'history' 
import allReducers from './reducers';
import {createStore, applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import { networkMiddleware } from '../network/networkmiddleware';
export const history = createBrowserHistory()

export const store = createStore(
    allReducers,
    applyMiddleware(networkMiddleware,thunk)
);