import React from 'react';
import { Grid, Typography, Button } from '@mui/material';
import { Box,styled } from '@mui/system'
import { Link,useNavigate } from "react-router-dom";

const Header = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  minHeight: '600px',
  alignItems: 'center',
  justifyContent: 'center',
}))


const gridContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  maxWidth: '1300px',
  padding: '50px',
}))

const Title = styled(Typography)(({ theme }) => ({
  paddingBottom: '15px',
}))

const SubTitle = styled(Typography)(({ theme }) => ({
  opacity: '0.4',
  paddingBottom: '30px',
}))



const Hero = () => {

  const navigate = useNavigate();

  const openPage =  (link)=>{
    navigate(link)
  }

  return (
    <Header>
      <Grid container spacing={6}>
      <Grid item xs={12} md={1}></Grid>
        <Grid item xs={12} md={6} >
          <Box sx={{ display: 'flex' ,  flexDirection: 'column' ,justifyContent: 'center',height:'100%'}}>
            <Title variant="h3" fontWeight={600}>
              Welcome to ALT Code
            </Title>
            <SubTitle variant="h6">
              It is a platform for students to become a champ in coding skills for Tech interviews.Participate in Daily coding challenges and become a pro in coding.
            </SubTitle>
            <Button
              variant="contained"
              color="primary"
              onClick={()=>{openPage('/coding/daily')}}
              sx={{ width: '200px', fontSize: '16px' }}
            >
              Explore
            </Button>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <img src="./undraw_developer.svg" alt="My Team" style={{width:"100%",height:'100%'}}/>
        </Grid>
        <Grid item xs={12} md={1}></Grid>
      </Grid>
    </Header>
  );
};

export default Hero;