import { CANCEL_ERROR, SHOW_ERROR } from "../../constants/actionTypes";

const initialState = {
    error : false,
    errorHeading:'',
    errorMessage:'',
    errorType:''
};
    
function errorDialogReducer(state = initialState, action) {
    switch (action.type) {
        case SHOW_ERROR:  return { ...state, error: true,errorHeading:action.heading,errorMessage:action.message }
        case CANCEL_ERROR: return { ...state, error: false,errorHeading:'',errorMessage:''}
        default:return state;
    }
};
        
export default errorDialogReducer;