import React, { useState, useEffect ,Fragment,useCallback } from 'react'

import  {Terminal} from 'xterm'
import {io} from "socket.io-client";
import './xterm.css';
import { FitAddon } from 'xterm-addon-fit'; 
const TerminalComponent = (props) => {

    const serverAddress = "https://coderunner.altcode.net";
    // const serverAddress='http://34.125.42.105'

    let socket;

    let terminal; 

    /**
   * Attach event listeners for terminal UI and socket.io client
   */
  let startListening = ()=> {
    socket.on("output", data => {
      // When there is data from PTY on server, print that on Terminal.
      console.log("socket data",data)
      if(data == 'close-socket'){
        socket.close()
        write("\nProgram Execution Finished")
      }else if(data.includes('Program exited')){
        let str = data.replace("Program exited","")
        write(str)
      }else{
        write(data);
      }

    });
  }

  /**
   * Print something to terminal UI.
   */
  let write = (text)=> {
    terminal.write(text);
  }

  /**
   * Utility function to print new line on terminal.
   */
  let prompt = ()=> {
    terminal.write(`\r\n$ `);
  }

  /**
   * Send whatever you type in Terminal UI to PTY process in server.
   * @param {*} input Input to send to server
   */
  let sendInput = (input) => {
    socket.emit("input", input);
  }

  /**
   *
   * @param {HTMLElement} container HTMLElement where xterm can attach terminal ui instance.
   */
  let attachTo = (container)=> {

         terminal = new Terminal({
                fontFamily: "'Roboto Mono', monospace",
                cols: 100,
                cursorBlink: true,
                theme: { background: '#E7E9EB',foreground:'#000' }
        });
        const fitAddon = new FitAddon();
        terminal.loadAddon(fitAddon);
        terminal.open(container);
        fitAddon.fit();
  }

  let clear = ()=> {
    terminal.clear();
  }


    useEffect(() => {
        try {
            const initTerm = async () => {
               
              if(terminal==null){
                let myNode = document.getElementById('x_terminal')
                while (myNode.lastElementChild) {
                  myNode.removeChild(myNode.lastElementChild);
                }
                attachTo(myNode);

                console.log("terminal attached")

                socket = io(serverAddress);

                startListening();

                console.log("Connecting to socket")

                terminal.onData(data => sendInput(data));

              }else{
                terminal.clear();
              }

                socket.emit('start',{file:props.fileId,langMode:props.langMode})


            }
            initTerm();
        } catch (ex) {
            console.log(ex)
        }
    }, [props.fileId])

    return(<div id = "x_terminal" > </div>)
}

export default TerminalComponent;