import { LOAD_LEADERBOARD, LOAD_LEADERBOARD_SUCCESS, LOAD_LEADERBOARD_FAILURE} from "../../constants/actionTypes";

const initialState = {
    loading : false,
    weeks:{}
};
    
function leaderBoardReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_LEADERBOARD:  return { ...state, loading: true }
        case LOAD_LEADERBOARD_SUCCESS: {
            console.log("LOAD_LEADERBOARD action",action.payload)
            return { ...state, weeks:action.payload, loading: false}
        }
        case LOAD_LEADERBOARD_FAILURE: return { ...state,loading: false}
        default:return state;
    }
};
        
export default leaderBoardReducer;