import { Typography } from  '@mui/material'
import SimpleCard from '../component/SimpleCard'
import {
    Button,
    Backdrop,
    CircularProgress,
} from '@mui/material'
import { Box,styled } from '@mui/system'
import React, { useState, useEffect ,Fragment,useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { fetchChallengePrograms,showError,closeError, deleteProgram } from '../actions'
import { ErrorDialog } from '../component/ErrorDialog'
import { useNavigate  } from 'react-router-dom'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmationDialog } from '../component/ConfimationDialog'

const Container = styled('div')(({ theme }) => ({
    margin: '30px',
    [theme.breakpoints.down('sm')]: {
        margin: '16px',
    },
}))
  
const FlexBox = styled(Box)(() => ({
display: 'flex',
alignItems: 'center',
}))

const JustifyBox = styled(FlexBox)(() => ({
justifyContent: 'center',
}))

const StyledButton = styled(Button)(({ theme }) => ({
margin: theme.spacing(5)
}))


function DailyCoding(){

    const dispatch = useDispatch();

    const navigate = useNavigate();
    
    const loading = useSelector((state) => state.potd.loading);
  
    const [confirm,showConfirm] = useState(false)

    const [index,setIndex] = useState(-1)

    const programs = useSelector((state) => state.potd.programs) ;

    const [published,setPublished] = useState(null)
    const [remainingTime,setRemainingTime] = useState({hours:0,minutes:0,seconds:0})


    const user = useSelector((state) => state.user.instance);

    const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];

    function formatDate(date) {
        var d = new Date(date)
        var month = ''+monthNames[d.getMonth()]
        var day = '' + d.getDate()
        var year = d.getFullYear();

        if (day.length < 2) 
            day = '0' + day;
    
        return day+" "+month
    }

    const solveChallenge = (index)=>{
        console.log("Solve Challenge")
        navigate('/solveproblem',{ state:{program: programs[index] ,user:user}})
    }

    const editChallenge = (index)=>{
        console.log("Edit Challenge",index)
        navigate('/coding/upload',{ state:{programMeta: programs[index],editMode:true }})
    }

    const deleteChallenge = (index)=>{
      setIndex(index)
      showConfirm(true)
    }

    const confirmDelete = useCallback(() => {
        console.log("Delete Challenge",programs[index])
        dispatch(deleteProgram(programs[index]._id))
        showConfirm(false)
    })

    const closeDialog = useCallback(() => {
        showConfirm(false)
    })

    const getProgramsList = (programs)=>{

        let rows = programs.map((program,index)=>{
            return <><Box sx={{ display: 'flex',my:3 }}>
                <Box sx={{ flexGrow: 1 }}> 
                    <span style={{fontSize:'1.rem',marginBottom:'10px'}}>{formatDate(Date.parse(program.dateAdded))} | </span><span style={{padding:'3px',fontSize:'1.rem',backgroundColor:'green',color:'white',textAlign:'center',marginBottom:'10px',borderRadius:'4px'}}>{program.complexity}</span>              

                    <Typography sx={{fontSize:'1.5rem',mt:2}}>{program.programTitle}</Typography>
                </Box>
                {
                     user!=null&&user.userType=='admin'? <Box>
                        <EditIcon sx={{ fontSize:'2rem',my:3,p:0.75,color:'white',backgroundColor: "brown",borderRadius:'5px',cursor:'pointer'}} onClick={()=>{editChallenge(index)}}/>
                        <DeleteIcon sx={{ fontSize:'2rem',my:3,p:0.75,color:'white',backgroundColor: "red",borderRadius:'5px',mx:2,cursor:'pointer'}} onClick={()=>{deleteChallenge(index)}}/>
                     </Box>:<></>
                }
                               
                <Box>
                    <Button variant="contained" color="primary" sx={{p:3,fontWeight:500,maxHeight:'2rem',mt:2}} onClick={()=>solveChallenge(index)}>Solve Problem</Button>
                </Box>
                
                </Box>
                <hr class="solid"></hr>
                </>
        })

        return (<SimpleCard sx={{mt:3}}>
            {
               rows 
            }
        </SimpleCard>)
    }

    useEffect(() => {
        dispatch(fetchChallengePrograms())
    },[])

    useEffect(() => {
        let published = programs.filter((program)=>{
            return program.status=='published'
        })

        if(published!=null&&published.length>0){
            setPublished(published[0])

            let publishDate = new Date(Date.parse(published[0].datePublished))

            var date = new Date()
            date.setTime(publishDate.getTime() + 24 * 60 * 60 * 1000);
            
            setCountdownTimer(date.getTime())

            console.log("time",date,publishDate)
        }
        

    },[programs])


    const setCountdownTimer = (endTime)=>{
        var x = setInterval(function() {
            var now = new Date().getTime();
            var t = endTime - now;
            var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
            var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
            var seconds = Math.floor((t % (1000 * 60)) / 1000);
            
            setRemainingTime({...remainingTime,hours:hours,minutes:minutes,seconds:seconds})
            console.log("hours",endTime,hours,minutes,seconds)

            if (t < 0) {
                clearInterval(x);
            }
        }, 1000);
    }

    return (
        <Container>
            
            
            {
                published!=null?<SimpleCard sx={{mt:3}}>
                {
                    <>
                    <Box sx={{mt:3,mb:3}}>
                        <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.8rem'}}>Program of the Day</Typography>
                    </Box>
                    <Box sx={{ display: 'flex',my:1 }}>
                    <Box sx={{ flexGrow: 1 }}> 
                        <Typography sx={{fontSize:'1.5rem',mt:2}}>{published.programTitle}</Typography>
                    </Box>
                                   
                    <Box>
                        <Button variant="contained" color="primary" sx={{p:3,fontWeight:500,maxHeight:'2rem',mt:2,backgroundColor:'green'}} onClick={()=>solveChallenge(index)}>Solve Problem</Button>
                    </Box>

                    </Box>
                    <Box sx={{ display: 'flex',my:1 ,flexDirection: 'row',justifyContent: 'flex-end'}}>
                            <Typography sx={{py:1,textAlign:'center',my:'auto',fontSize:'1.3rem'}}>Time Left:</Typography>
                            <Box sx={{p:1}}><Typography sx={{fontSize:'1.2rem',fontWeight:500,mx:'auto'}}>{remainingTime.hours} </Typography><Typography>hrs</Typography></Box>

                            <Box sx={{p:1}}><Typography sx={{fontSize:'1.2rem',fontWeight:500,mx:'auto'}}>{remainingTime.minutes} </Typography><Typography>mins</Typography></Box>

                            <Box sx={{p:1}}><Typography sx={{fontSize:'1.2rem',fontWeight:500,mx:'auto'}}>{remainingTime.seconds} </Typography><Typography>secs</Typography></Box>

                    </Box>
                    </>
                }
                </SimpleCard>:<></>
            }

            <Box sx={{mt:3,mb:3}}>
                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.8rem'}}>Previous problems</Typography>
            </Box>

            {
                programs!=null && programs.length>0?getProgramsList(programs):<></>
            }

            {
                confirm ? <ConfirmationDialog show={confirm} confirmCall={confirmDelete} closeCall={closeDialog} heading="Delete" message="Confirm Delete?"/>:<></>
            }

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
        <CircularProgress color="inherit" sx={{mx:2}}/>
        <div>
          <h2>Please wait while loading programs...</h2>
        </div>
    </Backdrop>
</Container>)
}

export default DailyCoding