import React, { useState, useEffect ,Fragment} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Box,styled } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import { closeError } from '../actions'

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2)
  }))

export const ConfirmationDialog = ({show,confirmCall,closeCall,heading,message})=>{

    const dispatch = useDispatch();

    // const heading = useSelector((state) => state.error.errorHeading);

    // const message = useSelector((state) => state.error.errorMessage);

    const handleClose = () => {
        dispatch(closeError())
        closeCall()
    }

    const handleConfirm = () => {
        dispatch(closeError())
        confirmCall()
    }

    return <Dialog
                open={show}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">
            {heading}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {message}
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <StyledButton variant="contained" color="primary" onClick={handleConfirm} autoFocus>
                Confirm
            </StyledButton>
            <StyledButton variant="contained" color="secondary" onClick={handleClose} autoFocus>
                Cancel
            </StyledButton>
            </DialogActions>
            </Dialog>
}