import { UPLOAD_PROGRAMS, UPLOAD_PROGRAM_SUCCESS, UPLOAD_PROGRAM_FAILURE, } from "../../constants/actionTypes";

const initialState = {
    loading : false,
    status:{}
};
    
function uploadProgramReducer(state = initialState, action) {
    switch (action.type) {
        case UPLOAD_PROGRAMS:  return { ...state, loading: true }
        case UPLOAD_PROGRAM_SUCCESS: {
            console.log("LOGIN_USER_SUCCESS action",action.payload)
            return { ...state, status:action.payload.result, loading: false}
        }
        case UPLOAD_PROGRAM_FAILURE: return { ...state,loading: false}
        
        default:return state;
    }
};
        
export default uploadProgramReducer;