import React, { useState, useEffect ,Fragment} from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator'
import { Box,styled } from '@mui/system'
import { useSelector, useDispatch } from 'react-redux'
import { closeError } from '../actions'

const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(2)
  }))

export const EditBoxDialog = ({show,confirmCall,closeCall,heading,message})=>{

    const [name,setName] = useState('')

    const dispatch = useDispatch();

    const handleClose = () => {
        dispatch(closeError())
        closeCall()
    }

    const handleFieldMapChange = ({ target: { name, value } })  =>{
        setName(value)
    }

    const handleFormSubmit = (event) => {
        event.preventDefault();
        dispatch(closeError())
        confirmCall(name)
    }

    useEffect(() => {
        if(message!=null){
            setName(message)
        }
    },[])


    return <Dialog
                open={show}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <ValidatorForm onSubmit={handleFormSubmit}>
            <DialogTitle id="alert-dialog-title">
            {heading}
            </DialogTitle>
            <DialogContent>
            <DialogContentText id="alert-dialog-description">
                    <TextValidator
                            type="text"
                            name="fileName"
                            label="Enter File Name"
                            value={name}
                            validators={['required']}
                            sx={{my:2,mr:3,width:'100%'}}
                            onChange={handleFieldMapChange}
                            errorMessages={['this field is required']}
                        />
            </DialogContentText>
            </DialogContent>
            <DialogActions>
            <StyledButton variant="contained" color="primary" type="submit">
                Save
            </StyledButton>
            <StyledButton variant="contained" color="secondary" onClick={handleClose} autoFocus>
                Cancel
            </StyledButton>
            </DialogActions>
            </ValidatorForm>
            </Dialog>
}