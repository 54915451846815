import { CONSOLIDATE_RESULTS_FAILURE, CONSOLIDATE_RESULTS_LOAD, CONSOLIDATE_RESULTS_SUCCESS } from "../../constants/actionTypes";

const initialState = {
    loading : false,
    results:[]
};
    
function consolidateReducer(state = initialState, action) {
    switch (action.type) {
        case CONSOLIDATE_RESULTS_LOAD:  return { ...state, loading: true }
        case CONSOLIDATE_RESULTS_SUCCESS: {
            console.log("CONSOLIDATE_RESULTS_SUCCESS action",action.payload.results)
            return { ...state, results:action.payload.results, loading: false}
        }
        case CONSOLIDATE_RESULTS_FAILURE: return { ...state,loading: false}
        default:return state;
    }
};
        
export default consolidateReducer;