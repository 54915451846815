import { useMediaQuery } from "react-responsive";
import CodeEditor from "./code-editor";
import MobileEditor from "./mobileeditor";

const OnlineEditor = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)"
  });

  const isTablet = useMediaQuery({
    query: "(max-width: 1224px)"
  });

  const isMobile = useMediaQuery({
    query: "(max-width: 786px)"
  });

  const isPortrait = useMediaQuery({
    query: "(orientation: portrait)"
  });

  const isRetina = useMediaQuery({
    query: "(max-resolution: 300dpi)"
  });

  return (
    <div>
      {
        isDesktop ? <CodeEditor /> : <MobileEditor />
      }
      
    </div>
  )
}

export default OnlineEditor;