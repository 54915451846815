import { LOAD_PROGRAM_DETAIL, LOAD_PROGRAM_DETAIL_FAILURE, LOAD_PROGRAM_DETAIL_SUCCESS } from "../../constants/actionTypes";

const initialState = {
    loading : false,
    program:null
};
    
function programReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_PROGRAM_DETAIL:  return { ...state, loading: true }
        case LOAD_PROGRAM_DETAIL_SUCCESS: {
            console.log("LOAD_PROGRAM_DETAIL_SUCCESS action",action.payload)
            return { ...state, program:action.payload, loading: false}
        }
        case LOAD_PROGRAM_DETAIL_FAILURE: return { ...state,loading: false}
        
        default:return state;
    }
};
        
export default programReducer;