import { Outlet, Link } from "react-router-dom";
import Navbar from "./Navbar";
import {
    Grid
  } from '@mui/material'

import GoogleAds from "../ads/AdComponent";
import SkyScraperAd from "../ads/SkyScraperAd";
import React, { useState, useEffect ,Fragment} from 'react'
import LargeBannerAd from "../ads/LargeBannerAd";
import { useSelector, useDispatch } from 'react-redux'
import { ErrorDialog } from "./ErrorDialog";

const HomeLayout = () => {

  const error = useSelector((state) => state.error.error);


  useEffect(() => {
    <script>
     (adsbygoogle = window.adsbygoogle || []).push({});
    </script>
  })



  return (
    <>
      <Outlet />
      {
        error ? <ErrorDialog show={error}/>:<></>
      }
    </>
  )
};

export default HomeLayout;