import axios from 'axios';
// const API_ROOT = 'http://localhost:5001/all-results-server/us-central1/app';
const API_ROOT = 'https://us-central1-all-results-server.cloudfunctions.net/app';

const CODE_EDITOR = 'http://34.125.166.115:80/code/execute/';


let langMap={
    'java':62,
    'c_cpp':49,
    'cpp':53,
    'python':70,
}

let token = null;

export const setSession = (accessToken) => {
    if (accessToken) {
        localStorage.setItem('accessToken', accessToken)
        token = accessToken
    } else {
        localStorage.removeItem('accessToken')
        token = null
    }
}


export const fetchBatchResults = async (url,htNos) => {
    const params = {url:url, htNos:htNos}
    console.log("fetchBatchResults network call")
    try {
        let res = await axios({
             url: `${API_ROOT}/results/trigger`,
             method: 'get',
             headers: {
                 'Content-Type': 'application/json',
             },
             params:params
         })
         if(res.status == 200){
             // test for status you want, etc
             console.log(res.status)
             return {status:'success',result:res.data}
         }
         console.log(res)    
         // Don't forget to return something   
         return {status:'failure',result:'Something went wrong. Please try again later.'}
     }catch (err) {
         console.error(err);
         return {status:'failure',result:'Something went wrong. Please try again later.'}
     }
}

export const fetchConsolidatedResults = async (htNo) => {
    const params = {htNo:htNo}
    console.log("fetchBatchResults network call")
    try {
        let res = await axios({
             url: `${API_ROOT}/results/consolidate`,
             method: 'get',
             headers: {
                 'Content-Type': 'application/json',
             },
             params:params
         })
         if(res.status == 200){
             // test for status you want, etc 
             console.log(res.status)
             return {status:'success',result:res.data}
         }
         console.log(res)        
         // Don't forget to return something   
         return {status:'failure',result:'Something went wrong. Please try again later.'}
     }catch(err){
        console.log("executeCode error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }
}


export const saveSnippetCode = async (langMode,code,name,userId,tags)=>{

    let BASE_URL=`${API_ROOT}/programs/snippet/save`

    const formData = new FormData();
    formData.append('lang',langMode)
    formData.append('name',name)
    formData.append('code',code)
    formData.append('userId',userId)
    formData.append('tags',tags)

    try{
        let response = await axios({
            url: BASE_URL,
            method: 'post',
            data: formData,
            headers: {"Authorization" : `Bearer ${token}`,'content-type': 'multipart/form-data'}
        })

        console.log("snippet code save status",response)

        if(response.status == 201){
            // test for status you want, etc
            console.log(response.status)
            return {...response.data,status:'success'}
        }

    }catch(err){
        console.log("saveSnippetCode error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }
}
export const updateSnippetCode = async (langMode,code,name,userId,tags,snippetId)=>{

    let BASE_URL=`${API_ROOT}/programs/snippet/update`

    const formData = new FormData();
    formData.append('lang',langMode)
    formData.append('name',name)
    formData.append('code',code)
    formData.append('userId',userId)
    formData.append('tags',tags)
    formData.append('id',snippetId)

    try{
        let response = await axios({
            url: BASE_URL,
            method: 'post',
            data: formData,
            headers: {"Authorization" : `Bearer ${token}`,'content-type': 'multipart/form-data'}
        })

        console.log("snippet code update status",response)

        if(response.status == 200){
            // test for status you want, etc
            console.log(response.status)
            return {...response.data,status:'success'}
        }

    }catch(err){
        console.log("updateSnippetCode error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }
}


export const executeCode = async (langMode,code,programId,userId,stdin)=>{

    let BASE_URL=`${API_ROOT}/programs/potd/execute`

    const formData = new FormData();
    formData.append('langMode',langMode)
    formData.append('code',code)
    formData.append('programId',programId)
    formData.append('userId',userId)
    formData.append('mode','compileandrun')
    
    if(stdin!=null&&stdin.length>0){
        formData.append('inputs',stdin)
    }

    try{
        let response = await axios({
            url: BASE_URL,
            method: 'post',
            data: formData,
            headers: {"Authorization" : `Bearer ${token}`,'content-type': 'multipart/form-data'}
        })

        console.log("first code submission status",response)

        if(response.status == 200){
            // test for status you want, etc
            console.log(response.status)
            return {...response.data}
        }

    }catch(err){
        console.log("executeCode error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }
}

export const submitCode = async (langMode,code,programId,userId)=>{

    let BASE_URL=`${API_ROOT}/programs/potd/execute`

    const formData = new FormData();
    formData.append('langMode',langMode)
    formData.append('code',code)
    formData.append('programId',programId)
    formData.append('userId',userId)
    formData.append('mode','challenge')

    try{
        let response = await axios({
            url: BASE_URL,
            method: 'post',
            data: formData,
            headers: {"Authorization" : `Bearer ${token}`,'content-type': 'multipart/form-data'}
        })

        console.log("first code submission status",response)

        if(response.status == 200){
            // test for status you want, etc
            console.log(response.status)
            return {...response.data}
        }
    }catch(err){
        console.log("submitCode error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }
}


export const runProgram = async (langMode,code,userId,stdin)=>{

    const jsonCode={}
    jsonCode['langMode']=langMode
    jsonCode['code']=code


    try{

    let response = await axios({
        url: 'https://coderunner.altcode.net/code/execute/',
        // url: 'http://34.125.42.105/code/execute/',
        method: 'post',
        data: JSON.stringify(jsonCode),
        headers: {"Authorization" : `Bearer ${token}`,'content-type': 'application/json'}
    })

    console.log("first code submission status",response)

    if(response.status == 200){
        // test for status you want, etc
        console.log(response.status)
        return {...response.data}
    }
    }catch(err){
        console.log("reset password error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }
}

export const loginUser = async (email,password) => {
    let BASE_URL=`${API_ROOT}/user/login`

    const formData = new FormData();
    formData.append('email',email)
    formData.append('password',password)

    try{

    let response = await axios({
        url: BASE_URL,
        method: 'post',
        data: formData,
    })

    console.log("first code submission status",response)

    if(response.status == 200){
        // test for status you want, etc
        console.log(response.status)
        setSession(response.data.accessToken)
        return {status:'success',result:response.data}
    }

    }catch(err){
        console.log("login user error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }

}

export const forgotPassword = async (email) => {
    let BASE_URL=`${API_ROOT}/user/forgotpassword`

    const formData = new FormData();
    formData.append('email',email)

    try{
        let response = await axios({
            url: BASE_URL,
            method: 'post',
            data: formData,
        })

        console.log("forgot password response",response)

        if(response.status == 200){
            // test for status you want, etc
            console.log(response.status)
            return {status:'success',result:response.data.message}
        }
    }catch(err){
        console.log("forgot password error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }
}

export const resetPassword = async (password,repassword,token) => {
    let BASE_URL=`${API_ROOT}/user/resetpassword`

    const formData = new FormData();
    formData.append('newPassword',password)
    formData.append('verifyPassword',repassword)
    formData.append('token',token)


    try{
        let response = await axios({
            url: BASE_URL,
            method: 'post',
            data: formData,
        })

        console.log("reset password response",response)

        if(response.status == 200){
            // test for status you want, etc
            console.log(response.status)
            return {status:'success',result:response.data.message}
        }
    }catch(err){
        console.log("reset password error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }
}

export const fetchProfile = async () => {
    let BASE_URL=`${API_ROOT}/user/profile`

    try{
        let response = await axios({
            url: BASE_URL,
            method: 'get',
            headers: {
                'Content-Type': 'application/json',
                "Authorization" : `Bearer ${token}`
            },
        })

        console.log("first code submission status",response)

        if(response.status == 200){
            // test for status you want, etc
            console.log(response.status)
            return {status:'success',result:response.data}
        }
    }catch(err){
        console.log("fetchProfile error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }

}

export const logout = async () => {
    setSession(null)
}


export const registerUser = async (email,password,collegeName,name) => {
    let BASE_URL=`${API_ROOT}/user/register`

    const formData = new FormData();
    formData.append('email',email)
    formData.append('pswd',password)
    formData.append('collegeName',collegeName)
    formData.append('name',name)

    try{

        let response = await axios({
            url: BASE_URL,
            method: 'post',
            data: formData,
        })

        console.log("first code submission status",response)

        if(response.status == 200){
            // test for status you want, etc
            console.log(response.status)
            return {status:'success',result:response.data}
        }
    }catch(err){
        console.log("registerUser error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
    }
}

export const uploadChallenge = async (challengeInfo) => {
    let BASE_URL=`${API_ROOT}/programs/upload/challenge`

    const formData = new FormData();
    formData.append('programTitle',challengeInfo.title)
    formData.append('programDesc',challengeInfo.description)
    formData.append('complexity',challengeInfo.complexity)
    formData.append('status',challengeInfo.programType)
    formData.append('companyTags',challengeInfo.companyTags)
    formData.append('programTags',challengeInfo.interviewYear)
    formData.append('solutionDesc',challengeInfo.solutionDesc)
    formData.append('programCode',challengeInfo.solutionCode)
    formData.append('inputs',challengeInfo.inputs)
    formData.append('output',challengeInfo.output)
    formData.append('testcases',challengeInfo.testcases)


    let response = await axios({
        url: BASE_URL,
        method: 'post',
        data: formData,
        headers: {"Authorization" : `Bearer ${token}`,'content-type': 'multipart/form-data'}
    })

    console.log("first code submission status",response)

    if(response.status == 201){
        console.log(response.status)
        return {status:'success',result:response.data}
    }
    console.log(response)        
    return {status:'failure',result:'Something went wrong. Please try again later.'}
}

export const deleteProgram = async (programId) => {
    let BASE_URL=`${API_ROOT}/programs/delete`
    const params = {_id:programId}
    let response = await axios({
        url: BASE_URL,
        method: 'delete',
        headers: {"Authorization" : `Bearer ${token}`},
        params:params
    })

    if(response.status == 200){
        // test for status you want, etc
        console.log(response.status)
        return {status:'success',result:response.data}
    }
    console.log(response)        
    return {status:'failure',result:'Something went wrong. Please try again later.'}
}

export const updateProgram = async (programId,challengeInfo) => {
    let BASE_URL=`${API_ROOT}/programs/update`

    const formData = new FormData();
    formData.append('id',programId)
    formData.append('programTitle',challengeInfo.title)
    formData.append('programDesc',challengeInfo.description)
    formData.append('complexity',challengeInfo.complexity)
    formData.append('execTestCases',challengeInfo.execTestCases)
    formData.append('execOutput',challengeInfo.expOutCome)
    formData.append('status',challengeInfo.programType)
    formData.append('companyTags',challengeInfo.companyTags)
    formData.append('programTags',challengeInfo.interviewYear)
    formData.append('solutionDesc',challengeInfo.solutionDesc)
    formData.append('programCode',challengeInfo.solutionCode)


    let response = await axios({
        url: BASE_URL,
        method: 'post',
        data: formData,
        headers: {"Authorization" : `Bearer ${token}`,'content-type': 'multipart/form-data'}
    })

    console.log("first code submission status",response)

    if(response.status == 200){
        console.log(response.status)
        return {status:'success',result:response.data}
    }
    console.log(response)        
    return {status:'failure',result:'Something went wrong. Please try again later.'}
}

export const fetchCodeSnippets = async () => {

    console.log("fetchCodeSnippets network call")
    try {
        let res = await axios({
             url: `${API_ROOT}/programs/snippet/list`,
             method: 'get',
             headers: {
                 'Content-Type': 'application/json',
                 "Authorization" : `Bearer ${token}`
             },
         })
         if(res.status == 200){
             // test for status you want, etc
             console.log(res.status)
             return {status:'success',result:res.data}
         }
     }catch (err) {
        console.log("fetchCodeSnippets error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
     }
}

export const deleteSnippet = async (programId) => {
    let BASE_URL=`${API_ROOT}/programs/snippet/delete`
    const params = {"_id":programId}
    let response = await axios({
        url: BASE_URL,
        method: 'delete',
        headers: {"Authorization" : `Bearer ${token}`},
        params:params
    })

    if(response.status == 200){
        // test for status you want, etc
        console.log(response.status)
        return {status:'success',result:response.data}
    }
    console.log(response)        
    return {status:'failure',result:'Something went wrong. Please try again later.'}
}



export const fetchPublishedChallenges = async () => {

    console.log("fetchPublishedChallenges network call")
    try {
        let res = await axios({
             url: `${API_ROOT}/programs/challenges/list`,
             method: 'get',
             headers: {
                 'Content-Type': 'application/json',
                 "Authorization" : `Bearer ${token}`
             },
         })
         if(res.status == 200){
             // test for status you want, etc
             console.log(res.status)
             return {status:'success',result:res.data}
         }
     }catch (err) {
        console.log("fetchPublishedChallenges error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
     }
}

export const fetchProgramDetail = async (programId) => {

    console.log("fetchProgramDetail network call")
    try {
        let res = await axios({
             url: `${API_ROOT}/programs/practice/`+programId,
             method: 'get',
             headers: {
                 'Content-Type': 'application/json',
                 "Authorization" : `Bearer ${token}`
             },
         })
         if(res.status == 200){
             // test for status you want, etc
             console.log(res.status)
             return {status:'success',result:res.data}
         }
     }catch (err) {
        console.log("fetchPublishedChallenges error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
     }
}

export const fetchPracticePrograms = async () => {

    console.log("fetchPracticePrograms network call")
    try {
        let res = await axios({
             url: `${API_ROOT}/programs/practice/list`,
             method: 'get',
             headers: {
                 'Content-Type': 'application/json',
                 "Authorization" : `Bearer ${token}`
             },
         })
         if(res.status == 200){
             // test for status you want, etc
             console.log(res.status)
             return {status:'success',result:res.data}
         }
         console.log(res)        
         return {status:'failure',result:'Something went wrong. Please try again later.'}
     }catch (err) {
        console.log("fetchPracticePrograms error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
     }
}

export const fetchLeaderBoard = async () => {

    console.log("fetchLeaderBoard network call")
    try {
        let res = await axios({
             url: `${API_ROOT}/programs/leaderboard/latest`,
             method: 'get',
             headers: {
                 'Content-Type': 'application/json',
                 "Authorization" : `Bearer ${token}`
             },
         })
         
         if(res.status == 200){
             // test for status you want, etc
             console.log(res.status)
             return {status:'success',result:res.data}
         }
         console.log(res)        
         return {status:'failure',result:'Something went wrong. Please try again later.'}
     }catch (err) {
        console.log("fetchLeaderBoard error",err.response)
        if(err.response){
            return {status:'failure',result:err.response.data.message}
        }else{
            return {status:'failure',result:'Please check your internet connection..'}
        }
     }
}


