import { CLEAR_SNIPPET, DELETE_SNIPPET, DELETE_SNIPPET_FAILURE, DELETE_SNIPPET_SUCCESS, FETCH_SNIPPETS, FETCH_SNIPPETS_FAILURE, FETCH_SNIPPETS_SUCCESS, SAVE_SNIPPET, SAVE_SNIPPET_FAILURE, SAVE_SNIPPET_SUCCESS, SET_CODE_SNIPPET, UPDATE_PROGRAM_SUCCESS, UPDATE_SNIPPET, UPDATE_SNIPPET_FAILURE, UPDATE_SNIPPET_SUCCESS } from "../../constants/actionTypes";

const initialState = {
    loading : false,
    snippets:[],
    codeSnippet:{}
};
    
function codeSnippetsReducer(state = initialState, action) {
    switch (action.type) {
        case UPDATE_SNIPPET:
            console.log("UPDATE_SNIPPET action")
            return { ...state, loading: true }
        case SAVE_SNIPPET:  return { ...state, loading: true }
        case UPDATE_SNIPPET_SUCCESS:
            let updateIndex = -1
            let newSnippets = JSON.parse(JSON.stringify(state.snippets)) 
            newSnippets.filter((snippet,index)=>{
              if(snippet._id == action.payload._id){
                updateIndex = index
              }

              return snippet._id == action.payload._id
            })

            if(updateIndex>=0){
                newSnippets[updateIndex] = action.payload
            }

            console.log("UPDATE_SNIPPET_SUCCESS action ",action.payload,updateIndex)

            return { ...state, codeSnippet:action.payload, snippets:newSnippets,loading: false}
        case SAVE_SNIPPET_SUCCESS: {
            console.log("SAVE_SNIPPET action",action.payload)
            let newSnippets = JSON.parse(JSON.stringify(state.snippets)) 
            newSnippets.unshift(action.payload)
            return { ...state, codeSnippet:action.payload, snippets:newSnippets, loading: false}
        }
        case UPDATE_SNIPPET_FAILURE:
        case SAVE_SNIPPET_FAILURE: return { ...state,loading: false}
        case FETCH_SNIPPETS:  return { ...state, loading: true }
        case FETCH_SNIPPETS_SUCCESS: {
            console.log("FETCH_SNIPPETS_SUCCESS action",action.payload)
            let mSnippets = action.payload
            let mCodeSnippet = mSnippets.length>0?mSnippets[0]:{}

            return { ...state, snippets:mSnippets,codeSnippet:mCodeSnippet, loading: false}
        }
        case FETCH_SNIPPETS_FAILURE: return { ...state,loading: false}
        case CLEAR_SNIPPET: return { ...state,loading: false,codeSnippet:{}}
        case SET_CODE_SNIPPET: return { ...state,loading: false,codeSnippet:action.payload}
        case DELETE_SNIPPET:  return { ...state, loading: true }
        case DELETE_SNIPPET_SUCCESS: {
            let delIndex = -1
            let newSnippets = JSON.parse(JSON.stringify(state.snippets)) 
            console.log("DELETE_SNIPPET_SUCCESS snippets length",newSnippets.length)
            newSnippets.filter((snippet,index)=>{
              if(snippet._id == action.payload.id){
                delIndex = index
              }   
              return snippet._id == action.payload.id
            })
            if(delIndex>=0){
                newSnippets.splice(delIndex,1)
            }
            console.log("DELETE_SNIPPET_SUCCESS action",action.payload,delIndex,newSnippets.length)
            let mCodeSnippet = newSnippets.length>0?newSnippets[0]:{}

            return { ...state, snippets:newSnippets,codeSnippet:mCodeSnippet, loading: false}
        }
        case DELETE_SNIPPET_FAILURE: return { ...state,loading: false}
        default:return state;
    }
};
        
export default codeSnippetsReducer;