import { LOAD_PRACTISE_PROBLEMS, LOAD_PRACTISE_PROBLEMS_SUCCESS, LOAD_PRACTISE_PROBLEMS_FAILURE} from "../../constants/actionTypes";

const initialState = {
    loading : false,
    programs:[]
};
    
function practiseProgramsReducer(state = initialState, action) {
    switch (action.type) {
        case LOAD_PRACTISE_PROBLEMS:  return { ...state, loading: true }
        case LOAD_PRACTISE_PROBLEMS_SUCCESS: {
            console.log("LOAD_POTD action",action.payload)
            return { ...state, programs:action.payload, loading: false}
        }
        case LOAD_PRACTISE_PROBLEMS_FAILURE: return { ...state,loading: false}
        default:return state;
    }
};
        
export default practiseProgramsReducer;