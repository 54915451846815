import { BATCH_RESULTS_FAILURE, BATCH_RESULTS_LOAD, BATCH_RESULTS_SUCCESS } from "../../constants/actionTypes";

const initialState = {
    loading : false,
    results:[]
};
    
function batchResultReducer(state = initialState, action) {
    switch (action.type) {
        case BATCH_RESULTS_LOAD:  return { ...state, loading: true }
        case BATCH_RESULTS_SUCCESS: return { ...state, results:action.payload.results, loading: false}
        case BATCH_RESULTS_FAILURE: return { ...state,loading: false}
        default:return state;
    }
};
        
export default batchResultReducer;