import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';


const theme = createTheme({
    palette: {
        type: 'light',
        primary: {
          // main: '#d84315',
          // main:'#5e17eb'
          main:'#5271ff'
        },
        secondary: {
          main: '#15abd8',
        },
        tomato: '#d84315',
        pink: {
            deep: '#FF1493',
            hot: '#FF69B4',
            medium: '#C71585',
            pale: '#DB7093',
            light: '#FFB6C1',
        },
      },
      typography: {
        fontFamily: 'Poppins',
      },
  });
  

ReactDOM.render(<ThemeProvider theme={theme}><App /></ThemeProvider>, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
