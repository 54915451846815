import AceEditor from "react-ace";
import { Menu, Tooltip, Typography } from  '@mui/material'
import SimpleCard from '../component/SimpleCard'
import { ValidatorForm, TextValidator,SelectValidator } from 'react-material-ui-form-validator'
import {
    Button,
    MenuItem,
    Table,
    Backdrop,
    Grid,
    CircularProgress,
} from '@mui/material'
import { Box,styled } from '@mui/system'
import React, { useState, useEffect ,Fragment,useCallback} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools"
import { clearSnippet, deleteSnippet, executeCodeResult, fetchSnippets, runCodeEditor, saveSnippet, setCodeSnippet, showError, updateSnippet } from "../actions";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import IconButton from '@mui/material/IconButton';
import { EditBoxDialog } from "../component/EditNameDialog";
import langMap from '../utils/languages.json';
import { AddRounded, Clear, FlareSharp, PlayArrow, PlayArrowRounded, SaveAsRounded,ArrowOutward } from "@mui/icons-material";
import TerminalComponent from "../component/TerminalComponent";
import MenuIcon from '@mui/icons-material/Menu';
import { slide as SideMenu } from 'react-burger-menu';
import './Sidebar.css';
import { LoginDialog } from "../component/LoginDialog";
import { useNavigate } from "react-router";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { Link } from "react-router-dom";
// const Container = styled('div')(({ theme }) => ({
//   }))

const Container = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        margin: '2px',
    },
  }))

const CodeBox = styled('div')(({ theme }) => ({
backgroundColor: '#E7E9EB',
width: '100%',
overflow: 'auto',
position: 'absolute',
top: '144px',
bottom: 0,
height: 'auto'
}))

const HeaderBox = styled('div')(({theme})=>({
    height: '58px',
    overflow: 'hidden',
    position: 'absolute',
    width: '100%',
    top: '85px',
    display:'flex',
    flexDirection:'row'
}))
  
  const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
  }))
  
  const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
  }))

function CodeEditor() {

    const javaCode = `\nclass Program{ 
    public static void main(String args[]){
        System.out.println("hello");
    }
}`;

let defaultResult = {
    mode:62,
    code:javaCode,
    stdinput:'',
    editorMode:'java'
    };

    const dispatch = useDispatch();

    const error = useSelector((state) => state.error.error);
  
    const fileId = useSelector((state) => state.codeRunner.fileId) ;

    const snippetLoading = useSelector((state) => state.snippets.loading);

    const snippets = useSelector((state) => state.snippets.snippets);

    const codeSnippet = useSelector((state) => state.snippets.codeSnippet);

    const user = useSelector((state) => state.user.instance);

    const [editName,setEditName]=useState(false)

    const [showSideBar,setSideBar]=useState(false)

    const [newCodeSnippets,setNewCodeSnippets]=useState([{}])

    const [result, setResult] = useState(defaultResult);

    const [terminalFlag, setTerminalFlag] = useState(false);

    const [loginFlag, setLoginFlag] = React.useState(false);

    const [snippetIndex, setSnippetIndex] = React.useState(-1);

    const navigate = useNavigate();

const handleLangChange = (value)  =>{
    var temp = {...result}
    temp['mode'] = value

    let language = langMap.languages.filter((lang)=>{
        return lang.id==value
    })

    temp['editorMode']=language[0].mode

    if(language[0].code){
        temp['code']=language[0].code
    }else{
        temp['code']='//write your code here'
    }

    console.log("coding editor",temp)

    setResult({...temp})

    dispatch(clearSnippet())

}

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

function isToday(date){
    // Create date from input value
    var inputDate = new Date(date);

    // Get today's date
    var todaysDate = new Date();

    // call setHours to take the time out of the comparison
    if(inputDate.setHours(0,0,0,0) == todaysDate.setHours(0,0,0,0)) {
        // Date equals today's date
        return true;
    }
    return false;
}

function formatDate(date) {
    var d = new Date(date)
    if(isToday(date)){
        return 'Today'
    }else{
        var month = ''+monthNames[d.getMonth()]
        var day = '' + d.getDate()
        var year = d.getFullYear();

        if (day.length < 2) 
            day = '0' + day;

        return day+" "+month
    }
}

function formatTime(date) {
    var d = new Date(date)
    return d.toLocaleTimeString();
}

const save = (name) => {
    if(user!=null&&user._id!=null){
        
        if(codeSnippet!=null&&codeSnippet._id!=null){
            //update code
            console.log("Snippet Update");
            dispatch(updateSnippet(result.mode,codeSnippet.programName,result.code,user._id,"",codeSnippet._id))
        }else{
            //save code
            if(typeof name != 'string'){
                //show name change dialog and then save
                setEditName(true)
                return;
            }

            let languages = langMap.languages.filter((lang)=>{
                return lang.id == result.mode
            })
            console.log("Snippet Save",languages);

            let lang;
            if(languages.length>0){
                lang = languages[0]
                dispatch(saveSnippet(result.mode,name+lang.ext,result.code,user._id,""))
            }else{
                dispatch(saveSnippet(result.mode,name,result.code,user._id,""))

            }

            // setTimeout(() => {   // ***
            //     dispatch(fetchSnippets())
            // }, 1000);

        }
    }else{
        // show the login error dialog
        dispatch(showError("Error","Please login to save code"))
    }
}

const reset = () => {
    console.log("new snippet");
    dispatch(clearSnippet())
}

const deleteSnip = (event,snippet) => {
    event.stopPropagation()
    console.log("delete snippet");
    dispatch(deleteSnippet(snippet._id))
}



const getSnippetsList = () =>{
    return <>
        <Box sx={{mt:2,mb:2}}>
                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.4rem',textDecoration: 'underline',color:'pink.hot'}}>Snippets List</Typography>
        </Box>
        {
            newCodeSnippets.map((snippet,index)=>{
                return <Box sx={{my:1,border:1,borderRadius:'5px',cursor:'pointer',borderColor:'secondary.main'}} onClick={()=>{openSnippet(snippet,index)}}>
                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                        <Typography sx={{my:1,mx:1,fontSize:'1.2rem',fontWeight:400,overflow:'hidden'}}>{snippet.programName}</Typography>
                        <IconButton aria-label="New" size="small" color="error" onClick={(event)=>{deleteSnip(event,snippet)}}>
                            <DeleteIcon fontSize="inherit" />
                        </IconButton>
                    </Box>
                    <Box sx={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
                    <Typography style={{fontSize:'0.9rem',color:'green',textAlign:'center'}} sx={{mb:1,mx:1}}>{snippet.lang}</Typography>
                    <Typography sx={{mb:1,mx:1,fontSize:'0.9rem',}}>{snippet.date}</Typography>

                    </Box>
                </Box>
            })
        }
    </>
}

const openSnippet = (snippet,index)=>{
    console.log("Snippet code",snippet)
    dispatch(setCodeSnippet(snippet))
    setSideBar(false)
    setSnippetIndex(index)
}


const handleFormSubmit = () => {
    dispatch(runCodeEditor(result.mode,result.code,user._id,result.stdinput))
}

function onChange(newValue) {
    console.log("change", newValue);
    setResult({...result,'code':newValue})
}

function handleOnClose() {
    console.log("sidebar closed");
    setSideBar(!showSideBar)
}

useEffect(() => {
console.log("languages",langMap)
if(user!=null && user._id!=null){
    console.log("fetch snippet list")
    dispatch(fetchSnippets())
    setLoginFlag(false)
    setSideBar(false)
}
},[user])

useEffect(() => {
    if(codeSnippet!=null&&codeSnippet.programCode!=null){
        let language = langMap.languages.filter((lang)=>{
            return lang.id==codeSnippet.language_code
        })
        setResult({...result,mode:codeSnippet.language_code,code:codeSnippet.programCode,editorMode:language[0].mode})
        
    }
},[codeSnippet])

useEffect(() => {
    console.log("snippets ",snippets)

    if(snippets!=null && snippets.length>0){
        let newSnippets = snippets.map((snippet)=>{

            let languages = langMap.languages.filter((lang)=>{
                return lang.id == snippet.language_code
            })

             snippet['lang'] = languages[0].short

             snippet['date'] = formatDate(Date.parse(snippet.lastUpdateDate))

             return snippet
            
        })

        setNewCodeSnippets(newSnippets)
        
    }else{
        setNewCodeSnippets([])
    }
},[snippets])

useEffect(() => {
    console.log("fileId ",fileId)
    if(fileId!=null&&fileId.length>0){
        setTerminalFlag(true)
    }
},[fileId])


const confirmSave = useCallback((name) => {
    setEditName(false)
    save(name)
})

const closeDialog = useCallback(() => {
    setEditName(false)
})

const handleLogin = (event) => {
    setSideBar(false);
    setLoginFlag(!loginFlag);
  };

  const hover = {
    "&:hover": {
      opacity: "1",
    },
  };
  return (<div style={{margin:0}}>

        <SideMenu isOpen={showSideBar} onClose={ handleOnClose }>
            {
                (user!=null&&user._id!=null)?<><Box sx={{width:'100%',overflow: 'auto'}}>
                        {
                            newCodeSnippets.length>0?getSnippetsList():<><Box sx={{width:'100%',display:'flex',justifyContent:'center',flexDirection:'column'}}>
                            <Typography style={{textAlign:'center',fontWeight:400,fontSize:'1.4rem',height:'100%'}}>No Snippets found.</Typography>
                        </Box></>
                        }
                            </Box></>:<><Box sx={{display:'flex',justifyContent:'center',flexDirection:'column'}}>
                    <Typography style={{textAlign:'center',fontWeight:400,fontSize:'1.4rem'}}>Login to fetch your snippets.</Typography>
                    <JustifyBox><Button variant="contained" color="secondary" sx={{mx:1}} onClick={(e)=>{handleLogin(e)}}>
                        Login
                    </Button></JustifyBox>
                </Box></>
            }
        </SideMenu>

        <Box sx= {{display:'flex', flexDirection:'row',my:2}}>
            <Box sx={{display:'flex', flexDirection:'row',cursor:'pointer'}} onClick={(e)=>{navigate('/')}}>
                <img src={process.env.PUBLIC_URL +'/new-logo.png'} width="80px" height="50px" style={{margin:'auto 10px'}}/>
                <Typography sx={{color:'#d84315',my:'auto',fontSize:'2em'}}>ALT Code</Typography>
            </Box>
            <Box sx={{flexGrow:1}}></Box>
            <Box sx={{mx:2,my:'auto'}} >
                <Link to={'/coding/practice'} style={{textDecoration:'none'}}><Button variant="contained" color="primary" sx={{mx:1,color:'white',my:'auto'}} endIcon={<ArrowOutwardIcon/>}>Practise Programs</Button></Link>
            </Box>
        </Box>

        <HeaderBox sx={{backgroundColor: '#5271ff'}}>
            <Box sx={{width:'50%',float:'left',display:'flex',flexDirection:'row',justifyContent:'center'}}>
            <Tooltip title="Snippets" ><IconButton size="large" sx={{color:'white',position:'absolute',left:'0px',my:'auto'}} onClick={(event)=>{setSideBar(!showSideBar)}}><MenuIcon></MenuIcon></IconButton></Tooltip>
            <Box sx={{height:'100%',alignContent:'center',flexDirection:'column',mt:1}}>
                    <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.4rem',color:'white'}}>{codeSnippet.programName?codeSnippet.programName:'Untitled'}</Typography>
                    <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'0.8rem',color:'white'}}>{codeSnippet.lastUpdateDate?'Saved at '+formatTime(codeSnippet.lastUpdateDate):''}</Typography>
            </Box>
            </Box>
            <Box sx={{px:1,width:'50%',float:'right'}}>
                <Box sx={{display:'flex',flexDirection:'row',justifyContent: 'end',py:1}}>
                    <Button variant="contained" color="secondary" onClick={reset} sx={{mx:1,color:'white'}} endIcon={<AddIcon />}>
                        New
                    </Button>
                    <Button variant="contained" color="success" onClick={save} sx={{mx:1}} endIcon={<SaveIcon />}>
                        Save
                    </Button>

                    <Button variant="contained" color="error" sx={{mx:1}} onClick={handleFormSubmit} endIcon={<PlayArrow />}>
                        Execute
                    </Button>
                </Box>
            </Box>
            </HeaderBox>
            <CodeBox>
            <div style={{float:'left',width:'4%',height:'100%',backgroundColor:'#24292f'}}>
            <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',width:'100%',height:'100%'}}>
                {
                    langMap.languages.map((language)=>{
                        return  <Tooltip title={language.name}><Box sx={{p:0.5}}><Box sx={{display:'flex',flexDirection:'column',alignItems:'center',opacity:language.id==result.mode?'1':'0.6',cursor:'pointer','&:hover':{opacity:'1'},overflow:'hidden',backgroundColor:language.id==result.mode?'white':''}} onClick={(e)=>{handleLangChange(language.id)}}>
                            <img src={language.icon} style={{width:'80%', height:'80%',padding:'2px'}}></img>
                        </Box></Box></Tooltip>
                    })
                }
            </Box>
            </div>
            <div style={{float:'left',width:'51%'}}>
                    <AceEditor
                        mode={result.editorMode}
                        theme="monokai"
                        onChange={onChange}
                        fontSize={18}
                        name="code"
                        style={{position: "absolute",top: "0px",width:'50%',height: "100%",bottom: "0px"}}
                        showPrintMargin={true}
                        showGutter={true}
                        highlightActiveLine={true}
                        editorProps={{ $blockScrolling: true }}
                        value={result.code}
                        setOptions={{
                        enableBasicAutocompletion: false,
                        enableLiveAutocompletion: false,
                        enableSnippets: true
                        }}
                    />

            </div>
            <div style={{float:'right',width:'45%',height:'auto',overflow:'auto'}}>
                    <div style={{width:'100%'}}>
                        <JustifyBox>
                        <Typography style={{textAlign:'center',fontWeight:400,fontSize:'1.6rem',height:'100%',my:'auto',color:'grey'}}>Output</Typography>
                        </JustifyBox>
                               {
                                   terminalFlag?<TerminalComponent fileId={fileId} langMode={result.mode} sx={{width:'100%',height:'auto',border:1,borderColor:'primary.main'}}/>:<></>
                               }
            </div>
            </div>
            </CodeBox>
              {
                editName ? <EditBoxDialog show={editName} confirmCall={confirmSave} closeCall={closeDialog} heading="File Name" message="Untitled"/>:<></>
            }


        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={snippetLoading}
        >
        <CircularProgress color="inherit" sx={{mx:2}}/>
        <div>
          <h2>Please wait...</h2>
        </div>
    </Backdrop>

    {
        loginFlag?<LoginDialog></LoginDialog>:<></>
      }
  </div>
  )

}

export default CodeEditor;