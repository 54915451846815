import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home";
import Faq from "./pages/faq";
import Layout from "./component/Layout";
import NoPage from "./pages/NoPage";
import UploadProgram from "./pages/uploadchallenge";
import DailyCoding from "./pages/dailyCoding";
import PracticePrograms from "./pages/practiceprograms";
import UserProfile from "./pages/userprofile"
import CodeEditor from "./pages/code-editor"
import { Provider } from 'react-redux';
import SolveProgramEditor from "./pages/SolveProgramEditor";
import { Outlet, Link } from "react-router-dom";
import {store} from './redux/store';
import { createTheme, ThemeProvider} from '@mui/material/styles';
import HomeLayout from "./component/HomeLayout";
import CodersLeaderBoard from "./pages/CodersLeaderBoard";
import ForgotPassword from "./pages/forgotpassword";
import ResetPassword from "./pages/resetpassword";
import OnlineEditor from "./pages/onlineeditor";
function App() {
  return (
    <Provider store={store}>
    <Router>
      <Routes>
        <Route path="/" element={<HomeLayout />}>
          <Route index element={<OnlineEditor />} />
          <Route path="coding" element={<Layout />}>
            <Route path="daily" element={<DailyCoding />} />
            <Route path="leaderboard" element={<CodersLeaderBoard />} />
            <Route path="practice" element={<PracticePrograms />} />
            <Route path="upload" element={<UploadProgram />} />
        </Route>
        <Route path="solveproblem/:programId/" element={<SolveProgramEditor />} />
        <Route path="user" element={<Layout />}>
          <Route path="profile" element={<UserProfile />} />
          <Route path="forgotpassword" element={<ForgotPassword />} />
          <Route path="resetpassword" element={<ResetPassword />} />
        </Route>
        {/* <Route path="editor" element={<CodeEditor />} /> */}
        <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </Router>
    </Provider>
  );
}
export default App;
