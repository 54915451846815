import AceEditor from "react-ace";
import { Backdrop, Tooltip, Typography } from  '@mui/material'
import SimpleCard from '../component/SimpleCard'
import { ValidatorForm, TextValidator,SelectValidator } from 'react-material-ui-form-validator'
import {
    Button,
    MenuItem,
    Grid,
    CircularProgress,
} from '@mui/material'
import {
    AppBar,
    Toolbar,
    CssBaseline,
    useMediaQuery,
  } from  '@mui/material'
import TabsUnstyled from '@mui/base/TabsUnstyled';
import TabsListUnstyled from '@mui/base/TabsListUnstyled';
import TabUnstyled, { tabUnstyledClasses } from '@mui/base/TabUnstyled';
import { Box,styled } from '@mui/system'
import React, { useState, useEffect ,Fragment} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import "ace-builds/src-noconflict/mode-java";
import "ace-builds/src-noconflict/mode-c_cpp";
import "ace-builds/src-noconflict/mode-python";
import "ace-builds/src-noconflict/theme-github";
import "ace-builds/src-noconflict/theme-monokai";
import "ace-builds/src-noconflict/ext-language_tools"
import { executeCodeResult,loadProgramDetail,submitCodeResult } from "../actions";
import { useLocation, useParams } from "react-router-dom";
import { sanitize } from 'dompurify';
import { buttonUnstyledClasses } from '@mui/base/ButtonUnstyled';
import TabPanelUnstyled from '@mui/base/TabPanelUnstyled';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate} from "react-router-dom";
import { CodeRunDialog } from "../component/CodeRunDialog";
import { logout, profile } from '../actions';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import { LoginDialog } from "../component/LoginDialog";

const Container = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        margin: '2px',
    },
  }))

  const CodeBox = styled('div')(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        margin: '2px',
    },
    backgroundColor: '#E7E9EB',
    width: '100%',
    position: 'absolute',
    top: '74px',
    bottom: 0,
  }))
  
  const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
  }))
  
  const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
  }))
  
  const StyledButton = styled(Button)(({ theme }) => ({
    margin: theme.spacing(5)
  }))

  const StyledProgress = styled(CircularProgress)(() => ({
    position: 'absolute',
    top: '6px',
    left: '25px',
}))

const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#3399FF',
    500: '#007FFF',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
  };
  
  const grey = {
    50: '#f6f8fa',
    100: '#eaeef2',
    200: '#d0d7de',
    300: '#afb8c1',
    400: '#8c959f',
    500: '#6e7781',
    600: '#57606a',
    700: '#424a53',
    800: '#32383f',
    900: '#24292f',
  };
  
  const Tab = styled(TabUnstyled)`
    font-family: IBM Plex Sans, sans-serif;
    color: #000;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    padding: 10px 12px;
    margin: 6px 6px;
    border: none;
    border-radius: 7px;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: ${blue[400]};
      color: #fff;
    }
  
    &:focus {
      color: #fff;
      outline: 3px solid ${blue[200]};
    }
  
    &.${tabUnstyledClasses.selected} {
      background-color: ${blue[600]};
      color: #fff;
    }
  
    &.${buttonUnstyledClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;
  
  const TabPanel = styled(TabPanelUnstyled)(
    ({ theme }) => `
    width: 100%;
    font-family: IBM Plex Sans, sans-serif;
    font-size: 1rem;
    padding: 20px 12px;
    overflow:'auto',
    height:'100%',
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    `,
  );
  
  const TabsList = styled(TabsListUnstyled)(
    ({ theme }) => `
    min-width: 400px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
    overflow:'auto';
    `,
  );


let PYTHON_KEY='python',JAVA_KEY='java',CPP_KEY='c_cpp';

function SolveProgramEditor() {

    const javaCode = `public class Main{
    public static void main(String args[]){
        System.out.println("hello");
    }
}`;

const langs = [{
  "id": 62,
  "name": "Java",
  "ext":".java",
  "mode":"java",
  "short":"Java",
  "code":"class Main{ \n\tpublic static void main(String args[]){\n\t\tSystem.out.println(\"hello\");\n\t}\n}",
  "icon":"./java.png"
},{
  "id": 71,
  "name": "Python",
  "ext":".py",
  "code":"def execute(): \n\t for i in range(10):\n\t\t print i \nexecute()",
  "short":"Python",
  "mode":"python",
  "icon":"./python.svg"
},]

    const location  = useLocation();

    const dispatch = useDispatch();

    const compileRun = useSelector((state) => state.codeRunner.compileRun);

    const submitCode = useSelector((state) => state.codeRunner.submitCode);

    const error = useSelector((state) => state.error.error);
  
    const codeResult = useSelector((state) => state.codeRunner.result) ;

    const program = useSelector((state) => state.program.program);

    const loading = useSelector((state) => state.program.loading);

    var [inputDialog, setInputDialog] = useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [profileEl, setProfileEl] = React.useState(null);
    const [loginFlag, setLoginFlag] = React.useState(false);
    const open = Boolean(anchorEl);
    const profileOpen = Boolean(profileEl);

    const openProfile = (event) => {
      setProfileEl(event.currentTarget);
    };
  
    const handleLogin = (event) => {
      setLoginFlag(!loginFlag);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setProfileEl(null)
    };

    const openPage =  (link)=>{
      navigate(link)
      setAnchorEl(null);
    }

    const logoutCall =()=>{
      dispatch(logout())
      setProfileEl(null)
    }
  
    const profileCall =()=>{
      openPage('/user/profile')
      dispatch(profile())
      setProfileEl(null)
    }
  

    const [inputs,setInputs] =  useState({
        values:[]
    });

    const user = useSelector((state) => state.user.instance);

    const  { programId } = useParams();

    const [result, setResult] = useState({
        mode:'java',
        code:'',
        stdinput:''
    });

    let navigate = useNavigate();


    const handleLangChange = (value)  =>{
    var temp = {...result}
    temp['mode'] = value

    if(value==PYTHON_KEY){
        temp['code']=program.methodSignatureInPython
    }else if(value==JAVA_KEY){
        temp['code']=program.methodSignatureInJava
    }else if(value==CPP_KEY){
        temp['code']=program.methodSignatureInC
    }

    setResult({...temp})
}

function onChange(newValue) {
    console.log("change", newValue);
    setResult({...result,'code':newValue})
}

  useEffect(() => {
        console.log("coderesult",codeResult)
        if(codeResult!=null && codeResult.length>0){
            console.log("code changed")
        }
  },[codeResult])

  useEffect(() => {
    console.log("Load Program Detail",programId)
    dispatch(loadProgramDetail(programId))
  },[])

  useEffect(() => {
    if(program!=null){
    let mInputs=[]
    program.inputs.map((input,index)=>{
        mInputs.push({...input,value:''})
    })
    setInputs({...inputs,values:mInputs})
    console.log("input values",mInputs)
    setResult({...result,code:program.methodSignatureInJava})
  }
  },[program])


  function submitProgram(){
    console.log("submit program")
    dispatch(submitCodeResult(result.mode,result.code,program._id,user._id))
  }

  function showCodeRunDialog(){
    setInputDialog(!inputDialog)
  }

  const closeDialogCallback=()=>{
    setInputDialog(!inputDialog)
  }

  const inputValuesCallback = (inputs)=>{
    setInputDialog(!inputDialog)
    dispatch(executeCodeResult(result.mode,result.code,program._id,user._id,JSON.stringify(inputs)))

  }


  return (
    <Container>
    <AppBar position="static">
      <CssBaseline />
      <Toolbar>
        <Box sx={{display:'flex',flexDirection:'row', width:'100%',justifyContent:'space-between'}}>
          <Box sx={{display:'flex',flexDirection:'row'}}>
        <ArrowBackIcon sx={{my:1}} onClick={() => navigate(-1)}></ArrowBackIcon>
        <Typography sx={{fontWeight:400,fontSize:'1.5rem',mx:2,textAlign:'center'}}>{program!=null?program.programTitle:''}</Typography>
        </Box>

        <Box>
        {
              user!=null&&user.email!=null&&user.email.length>0 ?
              <> 

              <Button
              id="profile-button"
              aria-controls={profileOpen ? 'profile-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={profileOpen ? 'true' : undefined}
              variant="contained"
              disableElevation
              sx={{mx:1}}
              onClick={openProfile}
              endIcon={<KeyboardArrowDownIcon />}
              >
                {user.name}
              </Button>

                <Menu
                id="profile-menu"
                anchorEl={profileEl}
                open={profileOpen}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'profile-button',
                }}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: 'visible',
                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                    mt: 1.5,
                    '& .MuiAvatar-root': {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    '&:before': {
                      content: '""',
                      display: 'block',
                      position: 'absolute',
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: 'background.paper',
                      transform: 'translateY(-50%) rotate(45deg)',
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
              >
                <MenuItem onClick={()=>{profileCall()}}> <Avatar /> Profile</MenuItem>

              <MenuItem onClick={()=>{logoutCall()}}>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
                 Logout</MenuItem>
                
              </Menu></>
            :
            <Button
            id="basic-button"
            aria-controls={open ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
            onClick={handleLogin}
            variant="contained"
            sx={{mx:1}}
            disableElevation
          >
          Login
          </Button> 
            // <StyledText onClick={handleLogin}>
            //   Login
            // </StyledText>
            }
            </Box>
        </Box>
        </Toolbar>
        </AppBar>
        {program!=null?<CodeBox>
                    <div style={{float:'left',width:'50%',height:'100%',overflow:'auto'}}>
                        <div style={{width:'100%',padding:'10px'}}>
                        <TabsUnstyled defaultValue={0}>
                        <TabsList>
                            <Tab>Problem Statement</Tab>
                            <Tab>Program</Tab>
                            <Tab>Solution</Tab>
                        </TabsList>
                        <TabPanel value={0}><Fragment>
                                <div dangerouslySetInnerHTML={{__html:sanitize(program.programDesc)}}></div> 
                                        </Fragment></TabPanel>

                        <TabPanel value={1}><Fragment >
                        {
                            <>
                      <Box sx={{display:'flex',flexDirection:'row',width:'100%'}}>
                        <div style={{float:'left',width:'8%',backgroundColor:'#24292f'}}>
                          <Box sx={{display:'flex',flexDirection:'column',justifyContent:'center',width:'100%',height:'100%'}}>
                              {
                                  langs.map((language)=>{
                                      return  <Tooltip title={language.name}><Box sx={{p:0.5}}><Box sx={{display:'flex',flexDirection:'column',alignItems:'center',opacity:language.mode==result.mode?'1':'0.6',cursor:'pointer','&:hover':{opacity:'1'},overflow:'hidden',backgroundColor:language.mode==result.mode?'white':''}} onClick={(e)=>{handleLangChange(language.mode)}}>
                                          <img src={language.icon} style={{width:'100%', height:'100%',padding:'2px'}}></img>
                                      </Box></Box></Tooltip>
                                  })
                              }
                          </Box>
                          </div>


                            <AceEditor
                            mode={result.mode}
                            theme="monokai"
                            onChange={onChange}
                            fontSize={18}
                            name="code"
                            width='96%'
                            showPrintMargin={true}
                            showGutter={true}
                            highlightActiveLine={true}
                            editorProps={{ $blockScrolling: false }}
                            value={result.code}
                            setOptions={{
                            enableBasicAutocompletion: false,
                            enableLiveAutocompletion: false,
                            enableSnippets: true
                            }}
                            />

                        </Box>
                        </>
                           
                        }
                        </Fragment></TabPanel>
                        <TabPanel value={2}><Fragment>
                        {
                            (program.programType === 'practice' || (program.programType === 'challenge' && program.status === 'solution_announced'))?<>{program.solutionDesc===''?<><Typography>Solution is yet to be added for this problem</Typography></>:<><div dangerouslySetInnerHTML={{__html:sanitize(program.solutionDesc)}}></div></>}</>:<><Typography>Solution will be available after 24 hours..</Typography></>
                        }
                        </Fragment></TabPanel>
                        </TabsUnstyled>
                        </div>
                    </div>
                    <div style={{float:'right',width:'50%',height:'80%',overflow:'auto'}}>
                    <div style={{width:'100%',padding:'10px'}}>
                    <Grid container sx={{p:1,border:1,borderColor:grey[400]}}>
                    <Grid  lg={6} md={6} sm={6} xs={12} sx={{px:1}}>
                    </Grid>
                    <Grid  lg={6} md={6} sm={6} xs={12} sx={{px:1}}>
                    <Box sx={{display:'flex',justifyContent: 'flex-end',}}>
                        <Box sx={{mx:2,flexGrow: 1}}>
                            <StyledButton style={{ margin:'2px',fontWeight:500,minHeight:'40px',width:'100%'}} variant="contained" color="success" onClick={()=>{showCodeRunDialog()}}>
                                 {compileRun ? (
                                    <>
                                    <CircularProgress
                                        size={24}
                                        sx={{align:'center',color:'white'}}
                                        className="buttonProgress"
                                    />
                                    <span style={{marginLeft:"10px"}}> Loading </span></>
                                    
                                ):`Run`} 
                                 </StyledButton>
                        </Box>
                        <Box sx={{mx:2,flexGrow: 1}}>
                            <StyledButton style={{ margin:'2px',fontWeight:500,minHeight:'40px',width:'100%',color:'white'}} variant="contained" color="error" onClick={()=>{submitProgram()}}>
                                 {submitCode ? (
                                    <>
                                    <CircularProgress
                                        size={24}
                                        sx={{align:'center',color:'white'}}
                                        className="buttonProgress"
                                    />
                                    <span style={{marginLeft:"10px"}}> Loading </span></>
                                    
                                ):`Submit`} 
                                 </StyledButton>  
                        </Box>
                        </Box>
                    </Grid>
                    </Grid>
                    <Box sx={{ mt:3,width:'100%',height:'100%', padding:2 ,overflow: 'auto'}}>
                            {
                                (codeResult!=null && codeResult.length>0) ?<Typography style={{whiteSpace:'pre',fontSize:'1rem'}}>{codeResult}</Typography> :<JustifyBox sx={{height:'100%',}}>
                                <Typography style={{margin:'0px',textAlign:'center',fontWeight:400,fontSize:'1.5rem',color:grey[400]}}>Your output appears here</Typography>
                            </JustifyBox>
                            }
                        </Box>
                    </div>
                    </div>
        </CodeBox>:<></>}
            
        <Box py="12px" />
        {inputDialog?<CodeRunDialog show={true} codeInputs={inputs.values} closeCallback={closeDialogCallback} inputValuesCallback={inputValuesCallback}/> : <></>}

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={loading}
        >
        <CircularProgress color="inherit" sx={{mx:2}}/>
        <div>
          <h2>Please wait while loading program...</h2>
        </div>
        </Backdrop>

        {
        loginFlag?<LoginDialog></LoginDialog>:<></>
      }

  </Container>
  )

}

export default SolveProgramEditor;